import { useRef } from 'react';

// Chakra
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button
} from '@chakra-ui/react';

// I18next
import { useTranslation } from 'react-i18next';

interface IConfirmationDialogProps {
    title: string;
    message: string;
    isOpen: boolean;
    validateButtonClickHandler: Function;
    closeDialogHandler: Function;
};

const ConfirmationDialog: React.FC<IConfirmationDialogProps> = ({
    title,
    message,
    isOpen,
    validateButtonClickHandler,
    closeDialogHandler
}) => {
    const { t } = useTranslation();
    const cancelRef = useRef(null);

    return (
        <AlertDialog
            closeOnOverlayClick={false}
            isCentered
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            size={'sm'}
            onClose={() => closeDialogHandler()}
        >
            <AlertDialogOverlay />
            <AlertDialogContent>
                <AlertDialogHeader
                    fontSize='1.5rem'
                    fontWeight='700'
                >
                    {title}
                </AlertDialogHeader>
                <AlertDialogBody>{message}</AlertDialogBody>
                <AlertDialogFooter>
                    <Button
                        mr='1rem'
                        ref={cancelRef}
                        onClick={() => closeDialogHandler()}
                    >
                        {t('cancel')}
                    </Button>
                    <Button onClick={() => validateButtonClickHandler()}>
                        {t('validate')}
                    </Button>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    );
};

export default ConfirmationDialog;