// Chakra
import {
    Button,
    Flex,
    Heading
} from '@chakra-ui/react';

// I18next
import { useTranslation } from 'react-i18next';

// Utilities
import { anyElement } from 'utilities/arrayUtilities';

// View models
import { IApplicationUserForUserList } from 'viewmodels/ApplicationUser/IApplicationUserForUserList';

// Components
import EmptyList from 'components/EmptyList';
import Loader from 'components/Loader';
import UserList from 'components/UserList';

interface IUsersProps {
    users: IApplicationUserForUserList[];
    isFetchingUsers: boolean;
    addUserButtonClickHandler: Function;
    editUserHandler: Function;
};

const Users: React.FC<IUsersProps> = ({
    users,
    isFetchingUsers,
    addUserButtonClickHandler,
    editUserHandler
}) => {
    const { t } = useTranslation();

    return (
        <Flex direction='column'>
            <Heading
                as='h1'
                fontSize='1.5rem'
                mb='2rem'
                textAlign='center'
            >
                {t('userList')}
            </Heading>
            <UserListComponent
                users={users}
                isFetchingUsers={isFetchingUsers}
                editUserHandler={editUserHandler}
            />
            <Button
                colorScheme='blue'
                isDisabled={isFetchingUsers}
                mt='1rem'
                onClick={() => addUserButtonClickHandler()}
            >
                {t('addUser')}
            </Button>
        </Flex>
    );
};

interface IUserListComponentProps {
    users: IApplicationUserForUserList[];
    isFetchingUsers: boolean;
    editUserHandler: Function;
};

const UserListComponent: React.FC<IUserListComponentProps> = ({
    users,
    isFetchingUsers,
    editUserHandler
}) => {
    const { t } = useTranslation();
    
    if (isFetchingUsers) {
        return (
            <Loader />
        );
    }

    if (!anyElement(users)) {
        return (
            <EmptyList message={t('noOtherUserOtherThanYourselfWasFound')} />
        );
    }

    return (
        <UserList
            users={users}
            editUserHandler={editUserHandler}
        />
    );
};

export default Users;