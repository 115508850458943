// Chakra
import { chakra } from '@chakra-ui/react';

// Layouts
import Copyright from 'layouts/Copyright';

interface IFooterProps {
    owner: string;
    ownerWebsiteUrl?: string;
};

const Footer: React.FC<IFooterProps> = ({
    owner,
    ownerWebsiteUrl
}) => {
    return (
        <chakra.footer>
            <Copyright
                owner={owner}
                ownerWebsiteUrl={ownerWebsiteUrl}
            />
        </chakra.footer>
    );
};

export default Footer;