import {
    useEffect,
    useState
} from 'react';

// Chakra
import {
    useDisclosure,
    useToast
} from '@chakra-ui/react';

// Redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/Store';
import { RootState } from 'redux/Reducer';
import { setSelectedDateValues } from 'redux/slices/CalendarSlice';

// Axios
import { AxiosError } from 'axios';

// I18next
import { useTranslation } from 'react-i18next';

// Day
import dayjs from 'day';

// Constants
import { activityConfiguration } from 'applicationConstants';

// Utilities
import { getWorkDaysFromJwtToken } from 'utilities/appUtilities';
import { anyElement } from 'utilities/arrayUtilities';
import {
    getNextDayOfWeek,
    getPreviousDayOfWeek,
    isSameDate
} from 'utilities/dateUtilities';
import {
    formatTimeFromDate,
    formatTimeFromMilliseconds
} from 'utilities/timeUtilities';
import { isNullOrWhiteSpace } from 'utilities/stringUtilities';

// Services
import ActivityService from 'services/ActivityService';

// Form values
import { ActivityFormValues } from 'formValues/ActivityFormValues';

// Models
import { IActivityRequestForActivityEdition } from 'models/Activity/IActivityRequestForActivityEdition';

// View models
import { IDataForActivities } from 'viewmodels/Activities/IDataForActivities';
import { IActivityForActivityList } from 'viewmodels/Activity/IActivityForActivityList';

// Components
import Activities from 'components/Activities';
import ActivityForm from 'components/ActivityForm';
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog';
import Error from 'components/Error';
import Loader from 'components/Loader';
import TitledModal from 'components/modals/TitledModal';

const ActivitiesContainer = () => {
    const dispatch = useAppDispatch();
    const toast = useToast();
    const { t } = useTranslation();
    const { configuration } = useSelector((state: RootState) => state.configuration);
    const { token } = useSelector((state: RootState) => state.user);
    const { selectedDateValues } = useSelector((state: RootState) => state.calendar);
    const {
        isOpen: isOpenActivityModal,
        onOpen: onOpenActivityModal,
        onClose: onCloseActivityModal
    } = useDisclosure();
    const {
        isOpen: isOpenActivityConfirmationDialog,
        onOpen: onOpenActivityConfirmationDialog,
        onClose: onCloseActivityConfirmationDialog
    } = useDisclosure();
    const [isFetchingData, setIsFetchingData] = useState<boolean>(false);
    const [isSubmittingActivity, setIsSubmittingActivity] = useState<boolean>(false);
    const [isDeletingActivity, setIsDeletingActivity] = useState<boolean>(false);
    const [data, setData] = useState<IDataForActivities | null>(null);
    const [selectedActivity, setSelectedActivity] = useState<
        {
            activity: IActivityForActivityList,
            action: 'Edit' | 'Duplicate' | 'Delete'
        } | null
    >(null);
    const workDays = token !== null ? getWorkDaysFromJwtToken(token) : undefined;

    useEffect(() => {
        const urlParameters = new URLSearchParams();
        
        urlParameters.append('year', selectedDateValues.year.toString());
        urlParameters.append('month', (selectedDateValues.month + 1).toString());
        urlParameters.append('day', selectedDateValues.day.toString());

        setIsFetchingData(true);

        ActivityService.fetchDataForActivities(urlParameters)
            .then((response) => {
                setData(response);
            })
            .catch((error: AxiosError) => {
                if (!error.response) {
                    return;
                }

                toast({
                    title: t('anErrorHasOccurred'),
                    description: t('errors.couldNotFetchYourActivities'),
                    status: 'error',
                    duration: 5000,
                    isClosable: true
                });
            })
            .finally(() => {
                setIsFetchingData(false);
            });
    }, [selectedDateValues]);

    const canWorkThatDay = () => {
        if (workDays === undefined || !anyElement(workDays)) {
            return false;
        }

        return workDays.includes(new Date(selectedDateValues.year, selectedDateValues.month, selectedDateValues.day).getDay());
    };

    const getRestDays = () => {
        if (workDays === undefined || !anyElement(workDays)) {
            return undefined;
        }

        return [0, 1, 2, 3, 4, 5, 6].filter(x => !workDays.includes(x));
    };

    const getActivityFormValues = () => {
        const formValues: ActivityFormValues = {
            date: new Date(selectedDateValues.year, selectedDateValues.month, selectedDateValues.day),
            startTime: '',
            endTime: '',
            duration: '',
            description: '',
            taskId: '',
            activityTypeId: ''
        };

        if (selectedActivity !== null) {
            formValues.description = selectedActivity.activity.description ?? '';
            formValues.taskId = selectedActivity.activity.task.id.toString();
            formValues.activityTypeId = selectedActivity.activity.activityType.id.toString();

            if (activityConfiguration.timeMode === 'Duration') {
                formValues.duration = formatTimeFromMilliseconds(dayjs(selectedActivity.activity.endDate).diff(dayjs(selectedActivity.activity.startDate)));
            } else {
                formValues.startTime = formatTimeFromDate(selectedActivity.activity.startDate);
                formValues.endTime = formatTimeFromDate(selectedActivity.activity.endDate);
            }
        }

        return formValues;
    };

    const handleSwitchDay = (action: 'Previous' | 'Next') => {
        if (workDays === undefined || !anyElement(workDays)) {
            return;
        }

        const selectedDate = new Date(selectedDateValues.year, selectedDateValues.month, selectedDateValues.day);
        const currentDayOfWeekIndex = workDays.findIndex(x => x === selectedDate.getDay());

        let newDayOfWeekIndex = 0;

        if (currentDayOfWeekIndex !== -1) {
            if (action === 'Previous') {
                if (currentDayOfWeekIndex === 0) {
                    newDayOfWeekIndex = workDays.length - 1;
                } else {
                    newDayOfWeekIndex = currentDayOfWeekIndex - 1;
                }
            } else {
                if (currentDayOfWeekIndex === workDays.length - 1) {
                    newDayOfWeekIndex = 0;
                } else {
                    newDayOfWeekIndex = currentDayOfWeekIndex + 1;
                }
            }
        }

        if (action === 'Previous') {
            const previousDate = getPreviousDayOfWeek(selectedDate, workDays[newDayOfWeekIndex]);

            dispatch(setSelectedDateValues({
                year: previousDate.getFullYear(),
                month: previousDate.getMonth(),
                day: previousDate.getDate()
            }));
        } else {
            const nextDate = getNextDayOfWeek(selectedDate, workDays[newDayOfWeekIndex]);

            dispatch(setSelectedDateValues({
                year: nextDate.getFullYear(),
                month: nextDate.getMonth(),
                day: nextDate.getDate()
            }));
        }
    };

    const handleAddActivity = () => {
        setSelectedActivity(null);
        onOpenActivityModal();
    };

    const handleEditActivity = (activityId: number) => {
        const activity = data!.activities.find(x => x.id === activityId);

        if (activity === undefined) return;

        setSelectedActivity({
            activity,
            action: 'Edit'
        });
        onOpenActivityModal();
    };

    const handleDuplicateActivity = (activityId: number) => {
        const activity = data!.activities.find(x => x.id === activityId);

        if (activity === undefined) return;

        setSelectedActivity({
            activity,
            action: 'Duplicate'
        });
        onOpenActivityModal();
    };

    const handleActivitySubmit = (formValues: ActivityFormValues) => {
        const apiModel: IActivityRequestForActivityEdition = {
            year: formValues.date.getFullYear(),
            month: formValues.date.getMonth() + 1,
            day: formValues.date.getDate(),
            iso8601StartTime: null,
            iso8601EndTime: null,
            iso8601Duration: null,
            description: !isNullOrWhiteSpace(formValues.description) ? formValues.description : null,
            taskId: Number(formValues.taskId),
            activityTypeId: Number(formValues.activityTypeId)
        };

        if (!isNullOrWhiteSpace(formValues.duration)) {
            apiModel.iso8601Duration = dayjs.duration({
                hours: Number(formValues.duration.substr(0, 2)),
                minutes: Number(formValues.duration.substr(3, 2))
            }).toISOString(); // Iso 8601
        } else {
            apiModel.iso8601StartTime = dayjs.duration({
                hours: Number(formValues.startTime.substr(0, 2)),
                minutes: Number(formValues.startTime.substr(3, 2))
            }).toISOString(); // Iso 8601
            apiModel.iso8601EndTime = dayjs.duration({
                hours: Number(formValues.endTime.substr(0, 2)),
                minutes: Number(formValues.endTime.substr(3, 2))
            }).toISOString(); // Iso 8601
        }

        const urlParameters = new URLSearchParams();
        urlParameters.append('year', formValues.date.getFullYear().toString());
        urlParameters.append('month', (formValues.date.getMonth() + 1).toString());
        urlParameters.append('day', formValues.date.getDate().toString());

        if (selectedActivity !== null) {
            urlParameters.append('excludeActivityIds', selectedActivity.activity.id.toString());
        }

        ActivityService.fetchRemainingWorkTime(urlParameters)
            .then((response) => {
                const remainingWorkTimeDuration = dayjs.duration(dayjs.duration(response.iso8601RemainingWorkTime).asMilliseconds());
                let duration = dayjs.duration(0);

                if (!isNullOrWhiteSpace(formValues.duration)) {
                    const modelDuration = dayjs.duration({
                        hours: Number(formValues.duration.substr(0, 2)),
                        minutes: Number(formValues.duration.substr(3, 2))
                    });
                    duration = dayjs.duration(dayjs(remainingWorkTimeDuration.asMilliseconds()).diff(modelDuration.asMilliseconds()));
                } else {
                    const modelDuration = dayjs.duration(
                        dayjs(
                            dayjs.duration({
                                hours: Number(formValues.endTime.substr(0, 2)),
                                minutes: Number(formValues.endTime.substr(3, 2))
                            }).asMilliseconds()
                        ).diff(
                            dayjs(
                                dayjs.duration({
                                    hours: Number(formValues.startTime.substr(0, 2)),
                                    minutes: Number(formValues.startTime.substr(3, 2))
                                }).asMilliseconds()
                            )
                        )
                    );

                    duration = dayjs.duration(dayjs(remainingWorkTimeDuration.asMilliseconds()).diff(modelDuration.asMilliseconds()));
                }

                if (duration.asMilliseconds() < 0) {
                    toast({
                        title: t('anErrorHasOccurred'),
                        description: t('workTimeLimitHasBeenExceeded'),
                        status: 'error',
                        duration: 5000,
                        isClosable: true
                    });
                    return;
                }

                const isSameDateAsTheCurrentSelectedDate = isSameDate(
                    new Date(formValues.date.getFullYear(), formValues.date.getMonth(), formValues.date.getDate()),
                    new Date(selectedDateValues.year, selectedDateValues.month, selectedDateValues.day)
                );

                setIsSubmittingActivity(true);

                if (selectedActivity !== null && selectedActivity.action === 'Edit') {
                    ActivityService.updateMyActivity(selectedActivity.activity.id, apiModel)
                        .then((response) => {
                            setData(previousState => {
                                let totalWorkTimeDuration = dayjs.duration(dayjs.duration(previousState!.iso8601TotalWorkTime).asMilliseconds());
                                let remainingWorkTimeDuration = dayjs.duration(dayjs.duration(previousState!.iso8601RemainingWorkTime).asMilliseconds());
                                const oldDuration = dayjs.duration(dayjs(selectedActivity.activity.endDate).diff(selectedActivity.activity.startDate));
                                
                                if (!isSameDateAsTheCurrentSelectedDate) {
                                    totalWorkTimeDuration = totalWorkTimeDuration.subtract({
                                        hours: Math.abs(oldDuration.hours()),
                                        minutes: Math.abs(oldDuration.minutes())
                                    });
                                    remainingWorkTimeDuration = remainingWorkTimeDuration.add({
                                        hours: Math.abs(oldDuration.hours()),
                                        minutes: Math.abs(oldDuration.minutes())
                                    });

                                    return {
                                        ...previousState!,
                                        activities: [...previousState!.activities.filter(x => x.id !== selectedActivity.activity.id)],
                                        iso8601TotalWorkTime: totalWorkTimeDuration.toISOString(),
                                        iso8601RemainingWorkTime: remainingWorkTimeDuration.toISOString()
                                    };
                                }
                                else {
                                    const newDuration = dayjs.duration(dayjs(response.endDate).diff(response.startDate));
                                    const duration = dayjs.duration(dayjs(newDuration.asMilliseconds()).diff(oldDuration.asMilliseconds()));
                                    
                                    if (duration.hours() > 0 || duration.minutes() > 0) {
                                        totalWorkTimeDuration = totalWorkTimeDuration.add({
                                            hours: duration.hours(),
                                            minutes: duration.minutes()
                                        });
                                        remainingWorkTimeDuration = remainingWorkTimeDuration.subtract({
                                            hours: duration.hours(),
                                            minutes: duration.minutes()
                                        });
                                    } else {
                                        totalWorkTimeDuration = totalWorkTimeDuration.subtract({
                                            hours: Math.abs(duration.hours()),
                                            minutes: Math.abs(duration.minutes())
                                        });
                                        remainingWorkTimeDuration = remainingWorkTimeDuration.add({
                                            hours: Math.abs(duration.hours()),
                                            minutes: Math.abs(duration.minutes())
                                        });
                                    }

                                    return {
                                        ...previousState!,
                                        activities: [
                                            ...previousState!.activities.filter(x => x.id !== selectedActivity.activity.id),
                                            response
                                        ].sort((a, b) => {
                                            return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
                                        }),
                                        iso8601TotalWorkTime: totalWorkTimeDuration.toISOString(),
                                        iso8601RemainingWorkTime: remainingWorkTimeDuration.toISOString()
                                    };
                                }
                            });
                            onCloseActivityModal();
                            setSelectedActivity(null);
                        })
                        .catch((error: AxiosError) => {
                            if (!error.response) {
                                return;
                            }

                            let description = t('errors.couldNotUpdateActivity');

                            switch (error.response.data.code) {
                                case 'NOT_ALLOWED_TO_WORK_THAT_DAY':
                                description = t('youAreNotAllowedToWorkThatDay');
                                break;
                            }

                            toast({
                                title: t('anErrorHasOccurred'),
                                description: description,
                                status: 'error',
                                duration: 5000,
                                isClosable: true
                            });
                        })
                        .finally(() => {
                            setIsSubmittingActivity(false);
                        });
                } else {
                    ActivityService.createMyActivity(apiModel)
                        .then((response) => {
                            if (!isSameDateAsTheCurrentSelectedDate) {
                                onCloseActivityModal();
                                return;
                            }

                            setData(previousState => {
                                const duration = dayjs.duration(dayjs(response.endDate).diff(response.startDate));
                                let totalWorkTimeDuration = dayjs.duration(
                                    dayjs.duration(previousState!.iso8601TotalWorkTime).asMilliseconds()
                                ).add({
                                    hours: duration.hours(),
                                    minutes: duration.minutes()
                                });
                                let remainingWorkTimeDuration = dayjs.duration(
                                    dayjs.duration(previousState!.iso8601RemainingWorkTime).asMilliseconds()
                                ).subtract({
                                    hours: duration.hours(),
                                    minutes: duration.minutes()
                                });

                                return {
                                    ...previousState!,
                                    activities: [
                                        ...previousState!.activities,
                                        response
                                    ].sort((a, b) => {
                                        return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
                                    }),
                                    iso8601TotalWorkTime: totalWorkTimeDuration.toISOString(),
                                    iso8601RemainingWorkTime: remainingWorkTimeDuration.toISOString()
                                };
                            });
                            onCloseActivityModal();
                        })
                        .catch((error: AxiosError) => {
                            if (!error.response) {
                                return;
                            }

                            let description = t('errors.couldNotCreateActivity');

                            switch (error.response.data.code) {
                                case 'NOT_ALLOWED_TO_WORK_THAT_DAY':
                                description = t('youAreNotAllowedToWorkThatDay');
                                break;
                            }

                            toast({
                                title: t('anErrorHasOccurred'),
                                description: description,
                                status: 'error',
                                duration: 5000,
                                isClosable: true
                            });
                        })
                        .finally(() => {
                            setIsSubmittingActivity(false);
                        });
                }
            })
            .catch((error: AxiosError) => {
                if (!error.response) {
                    return;
                }

                toast({
                    title: t('anErrorHasOccurred'),
                    description: t('errors.couldNotFetchRemainingWorkTime'),
                    status: 'error',
                    duration: 5000,
                    isClosable: true
                });
            });
    };

    const handleDeleteActivity = (activityId: number) => {
        const activity = data!.activities.find(x => x.id === activityId);

        if (activity === undefined) return;

        setSelectedActivity({
            activity,
            action: 'Delete'
        });
        onOpenActivityConfirmationDialog();
    };

    const handleValidateDeleteActivity = () => {
        if (selectedActivity === null || selectedActivity.action !== 'Delete') {
            return;
        }

        setIsDeletingActivity(true);

        ActivityService.deleteMyActivity(selectedActivity.activity.id)
            .then(() => {
                setData(previousState => {
                    const duration = dayjs.duration(dayjs(selectedActivity.activity.endDate).diff(selectedActivity.activity.startDate));
                    let totalWorkTimeDuration = dayjs.duration(
                        dayjs.duration(previousState!.iso8601TotalWorkTime).asMilliseconds()
                    ).subtract({
                        hours: duration.hours(),
                        minutes: duration.minutes()
                    });
                    let remainingWorkTimeDuration = dayjs.duration(
                        dayjs.duration(previousState!.iso8601RemainingWorkTime).asMilliseconds()
                    ).add({
                        hours: duration.hours(),
                        minutes: duration.minutes()
                    });

                    return {
                        ...previousState!,
                        activities: [...previousState!.activities.filter(x => x.id !== selectedActivity.activity.id)],
                        iso8601TotalWorkTime: totalWorkTimeDuration.toISOString(),
                        iso8601RemainingWorkTime: remainingWorkTimeDuration.toISOString()
                    };
                });
                onCloseActivityConfirmationDialog();
                setSelectedActivity(null);
            })
            .catch((error: AxiosError) => {
                if (!error.response) {
                    return;
                }

                toast({
                    title: t('anErrorHasOccurred'),
                    description: t('errors.couldNotDeleteActivity'),
                    status: 'error',
                    duration: 5000,
                    isClosable: true
                });
            })
            .finally(() => {
                setIsDeletingActivity(false);
            });
    };

    if (isFetchingData) {
        return (
            <Loader />
        );
    }

    if (data === null) {
        return (
            <Error />
        );
    }

    return (
        <>
            <Activities
                data={data}
                year={selectedDateValues.year}
                month={selectedDateValues.month}
                day={selectedDateValues.day}
                timeMode={activityConfiguration.timeMode}
                isAddActivityButtonDisabled={!canWorkThatDay()}
                switchDayButtonClickHandler={handleSwitchDay}
                addActivityButtonClickHandler={handleAddActivity}
                editActivityHandler={handleEditActivity}
                duplicateActivityHandler={handleDuplicateActivity}
                deleteActivityHandler={handleDeleteActivity}
            />
            <TitledModal
                title={selectedActivity !== null ? t('editActivity') : t('addActivity')}
                isOpen={isOpenActivityModal}
                closeModalHandler={onCloseActivityModal}
            >
                <ActivityForm
                    formValues={getActivityFormValues()}
                    tasks={data.tasks}
                    activityTypes={data.activityTypes}
                    timeMode={activityConfiguration.timeMode}
                    disabledDaysOfWeek={getRestDays()}
                    isDescriptionRequired={configuration!.isActivityDescriptionRequired}
                    isSubmitting={isSubmittingActivity}
                    submitHandler={handleActivitySubmit}
                />
            </TitledModal>
            <ConfirmationDialog
                title={t('deleteActivityQuestionMark')}
                message={t('components.confirmationDialog.deleteMessage')}
                isOpen={isOpenActivityConfirmationDialog}
                validateButtonClickHandler={handleValidateDeleteActivity}
                closeDialogHandler={onCloseActivityConfirmationDialog}
            />
        </>
    );
};

export default ActivitiesContainer;