// Chakra
import {
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input
} from '@chakra-ui/react';

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-light-svg-icons';

// I18next
import { useTranslation } from 'react-i18next';

// Hook form
import { useForm } from 'react-hook-form';

// Form values
import { ChangeEmailFormValues } from 'formValues/ChangeEmailFormValues';

interface IChangeEmailFormProps {
    formValues?: ChangeEmailFormValues;
    isSubmitting: boolean;
    submitHandler: Function;
};

const ChangeEmailForm: React.FC<IChangeEmailFormProps> = ({
    formValues,
    isSubmitting,
    submitHandler
}) => {
    const { t } = useTranslation();
    const {
        register,
        getValues,
        handleSubmit,
        formState: {
            isDirty,
            errors
        }
    } = useForm<ChangeEmailFormValues>({
        defaultValues: {
            email: formValues?.email ?? '',
            confirmEmail: formValues?.confirmEmail ?? '',
            password: formValues?.password ?? ''
        }
    });

    const handleSubmitForm = (model: ChangeEmailFormValues) => {
        if (!isDirty || isSubmitting) return;
        submitHandler(model);
    };

    return (
        <Flex
            as='form'
            direction='column'
            onSubmit={handleSubmit(handleSubmitForm)}
        >
            <FormControl
                id='email'
                isInvalid={errors.email !== undefined}
                isRequired
                mb='1rem'
            >
                <FormLabel>{t('email')}</FormLabel>
                <Input
                    type='email'
                    variant='filled'
                    {...register('email', {
                        required: t('emailRequired').toString(),
                        pattern: {
                            value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i,
                            message: t('invalidEmail').toString()
                        }
                    })}
                />
                {errors.email !== undefined && (
                    <FormErrorMessage as='span'>{errors.email.message}</FormErrorMessage>
                )}
            </FormControl>
            <FormControl
                id='confirmEmail'
                isInvalid={errors.confirmEmail !== undefined}
                isRequired
                mb='1rem'
            >
                <FormLabel>{t('confirmEmail')}</FormLabel>
                <Input
                    type='email'
                    variant='filled'
                    {...register('confirmEmail', {
                        required: t('emailRequired').toString(),
                        validate: value => value === getValues('email') || t('emailsDoesNotMatch').toString()
                    })}
                />
                {errors.confirmEmail !== undefined && (
                    <FormErrorMessage as='span'>{errors.confirmEmail.message}</FormErrorMessage>
                )}
            </FormControl>
            <FormControl
                id='password'
                isInvalid={errors.password !== undefined}
                isRequired
                mb='1rem'
            >
                <FormLabel>{t('password')}</FormLabel>
                <Input
                    type='password'
                    variant='filled'
                    {...register('password', {
                        required: t('passwordRequired').toString()
                    })}
                />
                {errors.password !== undefined && (
                    <FormErrorMessage as='span'>{errors.password.message}</FormErrorMessage>
                )}
            </FormControl>
            <Button
                colorScheme='blue'
                isDisabled={!isDirty}
                isLoading={isSubmitting}
                leftIcon={<FontAwesomeIcon icon={faCheck} />}
                type='submit'
            >
                {t('validate')}
            </Button>
        </Flex>
    );
};

export default ChangeEmailForm;