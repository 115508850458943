// Services
import requests from './ApiService';

// Models
import { IConfiguration } from 'models/Configuration/IConfiguration';

const routeName = 'configuration';

const ConfigurationService = {
    fetchApplicationConfiguration: (): Promise<IConfiguration> => requests.get(routeName)
};

export default ConfigurationService;