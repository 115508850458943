// Redux
import {
    createSlice,
    createAsyncThunk
} from '@reduxjs/toolkit';

// Axios
import { AxiosError } from 'axios';

// Services
import ConfigurationService from 'services/ConfigurationService';

// Models
import { IConfiguration } from 'models/Configuration/IConfiguration';

interface IConfigurationState {
    configuration: IConfiguration | null;
    isFetchingConfiguration: boolean;
};

const configurationInitialState: IConfigurationState = {
    configuration: null,
    isFetchingConfiguration: false
};

export const getConfiguration = createAsyncThunk(
    'configuration/getConfiguration',
    async (_, { rejectWithValue }) => {
        return await ConfigurationService.fetchApplicationConfiguration();
        /*try {
            return await ConfigurationService.fetchApplicationConfiguration();
        }
        catch (err) {
            let error: AxiosError = err;
            if (error !== undefined && error !== null) {
                return rejectWithValue(err.response);
            }
            throw err;
        }*/
    }
);

const configurationSlice = createSlice({
    name: 'configuration',
    initialState: configurationInitialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getConfiguration.pending, (state) => {
            state.isFetchingConfiguration = true;
        });
        builder.addCase(getConfiguration.fulfilled, (state, { payload }) => {
            state.configuration = payload;
            state.isFetchingConfiguration = false;
        });
        builder.addCase(getConfiguration.rejected, (state, { payload }) => {
            state.isFetchingConfiguration = false;
        });
    }
});

export default configurationSlice.reducer;