// Chakra
import {
    Button,
    Flex,
    Heading,
    Text
} from '@chakra-ui/react';

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faArrowLeft,
    faArrowRight
} from '@fortawesome/pro-light-svg-icons';

// I18next
import {
    Trans,
    useTranslation
} from 'react-i18next';

// Utilities
import { anyElement } from 'utilities/arrayUtilities';
import { formatDate } from 'utilities/dateUtilities';
import { formatTimeFromIso8601 } from 'utilities/timeUtilities';

// View models
import { IActivityForActivityList } from 'viewmodels/Activity/IActivityForActivityList';
import { IDataForActivities } from 'viewmodels/Activities/IDataForActivities';

// Components
import ActivityList from 'components/ActivityList';
import EmptyList from 'components/EmptyList';

interface IActivitiesProps {
    data: IDataForActivities;
    year: number;
    month: number;
    day: number;
    timeMode?: 'StartAndEnd' | 'Duration';
    isAddActivityButtonDisabled: boolean;
    switchDayButtonClickHandler: Function;
    addActivityButtonClickHandler: Function;
    editActivityHandler: Function;
    duplicateActivityHandler: Function;
    deleteActivityHandler: Function;
};

const Activities: React.FC<IActivitiesProps> = ({
    data,
    year,
    month,
    day,
    timeMode = 'StartAndEnd',
    isAddActivityButtonDisabled = false,
    switchDayButtonClickHandler,
    addActivityButtonClickHandler,
    editActivityHandler,
    duplicateActivityHandler,
    deleteActivityHandler
}) => {
    const { t } = useTranslation();

    return (
        <Flex direction='column'>
            <Heading
                as='h1'
                fontSize='1.5rem'
                mb='1rem'
                textAlign='center'
            >
                {t('yourActivitiesFromDate', { date: formatDate(new Date(year, month, day), 'dddd, DD/MM/YYYY') })}
            </Heading>
            <Flex
                justifyContent='space-between'
                mb='1rem'
            >
                <Button
                    colorScheme='blue'
                    leftIcon={<FontAwesomeIcon icon={faArrowLeft} />}
                    mr='1rem'
                    variant='ghost'
                    onClick={() => switchDayButtonClickHandler('Previous')}
                >
                    {t('previousDay')}
                </Button>
                <Button
                    colorScheme='blue'
                    rightIcon={<FontAwesomeIcon icon={faArrowRight} />}
                    variant='ghost'
                    onClick={() => switchDayButtonClickHandler('Next')}
                >
                    {t('nextDay')}
                </Button>
            </Flex>
            <Text as='span'>
                <Trans
                    i18nKey='labelComponentColonWithValue'
                    values={{
                        label: t('totalWorkTime'),
                        value: formatTimeFromIso8601(data.iso8601TotalWorkTime)
                    }}
                    components={{
                        label:
                            <Text
                                as='span'
                                fontWeight='700'
                            />
                    }}
                />
            </Text>
            <Text
                as='span'
                mb='1rem'
            >
                <Trans
                    i18nKey='labelComponentColonWithValue'
                    values={{
                        label: t('remainingWorkTime'),
                        value: formatTimeFromIso8601(data.iso8601RemainingWorkTime)
                    }}
                    components={{
                        label:
                            <Text
                                as='span'
                                fontWeight='700'
                            />
                    }}
                />
            </Text>
            <ActivityListComponent
                activities={data.activities}
                timeMode={timeMode}
                editActivityHandler={editActivityHandler}
                duplicateActivityHandler={duplicateActivityHandler}
                deleteActivityHandler={deleteActivityHandler}
            />
            <Button
                colorScheme='blue'
                isDisabled={isAddActivityButtonDisabled}
                mt='1rem'
                variant='solid'
                onClick={() => addActivityButtonClickHandler()}
            >
                {t('addActivity')}
            </Button>
        </Flex>
    );
};

interface IActivityListComponentProps {
    activities: IActivityForActivityList[];
    timeMode?: 'StartAndEnd' | 'Duration';
    editActivityHandler: Function;
    duplicateActivityHandler: Function;
    deleteActivityHandler: Function;
};

const ActivityListComponent: React.FC<IActivityListComponentProps> = ({
    activities,
    timeMode = 'StartAndEnd',
    editActivityHandler,
    duplicateActivityHandler,
    deleteActivityHandler
}) => {
    const { t } = useTranslation();

    if (!anyElement(activities)) {
        return (
            <EmptyList message={t('youHaveNotAddedAnyActivityYetThatDay')} />
        );
    }
    
    return (
        <ActivityList
            activities={activities}
            timeMode={timeMode}
            editActivityHandler={editActivityHandler}
            duplicateActivityHandler={duplicateActivityHandler}
            deleteActivityHandler={deleteActivityHandler}
        />
    );
};

export default Activities;