// Redux
import { combineReducers } from '@reduxjs/toolkit';

// Slices
import configurationSlice from './slices/ConfigurationSlice';
import userSlice from './slices/UserSlice';
import calendarSlice from './slices/CalendarSlice';

const reducer = combineReducers({
    configuration: configurationSlice,
    user: userSlice,
    calendar: calendarSlice
});

export type RootState = ReturnType<typeof reducer>;

export default reducer;