// Chakra
import {
    Flex,
    Select,
    Text
} from '@chakra-ui/react';

interface ILabelledSelectorProps {
    frontLabel?: string;
    backLabel?: string;
};

const LabelledSelector: React.FC<ILabelledSelectorProps> = ({
    frontLabel,
    backLabel,
    children,
    ...other
}) => {
    return (
        <Flex alignItems='center'>
            {frontLabel !== undefined && (
                <Text
                    as='span'
                    mr='.5rem'
                >
                    {frontLabel}
                </Text>
            )}
            <Select
                variant='filled'
                { ...other }
            >
                {children}
            </Select>
            {backLabel !== undefined && (
                <Text
                    as='span'
                    ml='.5rem'
                >
                {backLabel}
                </Text>
            )}
        </Flex>
    );
};

export default LabelledSelector;