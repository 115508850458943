// Chakra
import { Flex } from '@chakra-ui/react';

// Containers
import FooterContainer from 'containers/FooterContainer';

const CommonLayout: React.FC = ({ children }) => {
    return (
        <Flex
            direction='column'
            minHeight='100vh'
        >
            {children}
            <FooterContainer />
        </Flex>
    );
};

export default CommonLayout;