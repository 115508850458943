// Redux
import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

// Redux persist
import {
    persistReducer/*,
    FLUSH,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
    REHYDRATE,*/
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// Reducer
import reducer from './Reducer';

const version = 2;

const persistConfiguration = {
    key: `ibgraf_timesheet_v${version}`,
    version: version,
    storage,
    whitelist: [
        'user'
    ]
};

const persistedReducer = persistReducer(persistConfiguration, reducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false/*,
        serializableCheck: {
            ignoredActions: [FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE]
        }*/
    })
});

/*if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./Reducer', () => {
        const newReducer = require('./Reducer').default;
        store.replaceReducer(newReducer);
    })
}*/

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;