// I18next
import { useTranslation } from 'react-i18next';

// Router
import {
    Redirect,
    Route,
    Switch
} from 'react-router-dom';

// Helmet
import Helmet from 'react-helmet';

// Containers
import UserProfileContainer from 'containers/UserProfileContainer';

// Components
import ResponsiveContainer from 'components/ResponsiveContainer';

const MyAccountPage = () => {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>{t('myAccount')}</title>
            </Helmet>
            <ResponsiveContainer>
                <Switch>
                    <Route path='/:language/my-account/user-profile'>
                        <UserProfileContainer />
                    </Route>
                    <Redirect to='/:language/my-account/user-profile' />
                </Switch>
            </ResponsiveContainer>
        </>
    );
};

export default MyAccountPage;