// Responsive
import { useMediaQuery } from 'react-responsive';

// Configuration
import { screenBreakpoints } from 'applicationConstants';

const useIsComputerScreenSize = () => {
    return useMediaQuery({ minWidth: screenBreakpoints.mediumComputerBreakpoint });
};

export default useIsComputerScreenSize;