// Chakra
import {
    Button,
    Checkbox,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Switch,
    VStack
} from '@chakra-ui/react';

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-light-svg-icons';

// I18next
import { useTranslation } from 'react-i18next';

// Hook form
import { useForm } from 'react-hook-form';

// Day
import dayjs from 'day';

// Utilities
import { anyElement } from 'utilities/arrayUtilities';
import { isNullOrWhiteSpace } from 'utilities/stringUtilities';

// Form values
import { DefaultUserFormValues } from 'formValues/DefaultUserFormValues';
import { SubmitUserFormValues } from 'formValues/SubmitUserFormValues';

interface IUserFormProps {
    formValues?: DefaultUserFormValues;
    isCreationMode: boolean;
    isSubmitting: boolean;
    submitHandler: Function;
};

const UserForm: React.FC<IUserFormProps> = ({
    formValues,
    isCreationMode,
    isSubmitting,
    submitHandler
}) => {
    const { t } = useTranslation();
    const {
        register,
        watch,
        getValues,
        handleSubmit,
        formState: {
            isDirty,
            errors
        }
    } = useForm<DefaultUserFormValues>({
        defaultValues: {
            idMapping: formValues?.idMapping ?? '',
            userName: formValues?.userName ?? '',
            email: formValues?.email ?? '',
            password: formValues?.password ?? '',
            confirmPassword: formValues?.confirmPassword ?? '',
            firstName: formValues?.firstName ?? '',
            lastName: formValues?.lastName ?? '',
            /*
                Hook form version: 7.12.2
                useForm defaultValues object does not work for multiple checkboxes,
                it seems like checkboxes are checked when they should not
                Quick fix: use defaultChecked on Checkbox component
            */
            //workDays: formValues?.workDays ?? [],
            isAdministrator: formValues?.isAdministrator ?? false,
            isActive: formValues?.isActive ?? true
        }
    });
    const isActive = watch('isActive');
    const isAdministrator = watch('isAdministrator');
    const daysOfWeek = [1, 2, 3, 4, 5, 6, 0];

    // Quick fix (please take a look at useForm defaultValues object)
    const isWorkDayDefaultChecked = (dayOfWeek: number) => {
        if (formValues === undefined) {
            return false;
        }
        return (formValues.workDays).includes(dayOfWeek.toString());
    };

    const handleSubmitForm = (model: SubmitUserFormValues) => {
        if (!isDirty || isSubmitting) return;
        submitHandler(model);
    };

    return (
        <Flex
            as='form'
            direction='column'
            onSubmit={handleSubmit(handleSubmitForm)}
        >
            <Flex
                direction={{
                    base: 'column',
                    lg: 'row'
                }}
                mb='1rem'
            >
                <Flex
                    direction='column'
                    flex='1'
                    mb={{
                        base: '1rem',
                        lg: 0
                    }}
                    mr={{
                        lg: '2rem'
                    }}
                >
                    <Flex>
                        <FormControl
                            alignItems='center'
                            display='flex'
                            flex='1'
                            mb='1rem'
                        >
                            <Switch
                                id='isActive'
                                mr='.5rem'
                                {...register('isActive')}
                            />
                            <FormLabel
                                htmlFor='isActive'
                                mb='0'
                                mr='0'
                            >
                                {!isActive ? t('inactive') : t('active')}
                            </FormLabel>
                        </FormControl>
                        <FormControl
                            alignItems='center'
                            display='flex'
                            flex='1'
                            mb='1rem'
                        >
                            <Switch
                                id='isAdministrator'
                                mr='.5rem'
                                {...register('isAdministrator')}
                            />
                            <FormLabel
                                htmlFor='isAdministrator'
                                mb='0'
                                mr='0'
                            >
                                {!isAdministrator ? t('user') : t('administrator')}
                            </FormLabel>
                        </FormControl>
                    </Flex>
                    <FormControl
                        id='idMapping'
                        isInvalid={errors.idMapping !== undefined}
                        mb='1rem'
                    >
                        <FormLabel>{t('idMapping')}</FormLabel>
                        <Input
                            variant='filled'
                            {...register('idMapping', {
                                maxLength: { value: 100, message: t('idMappingMaximumLength', { length: 100 }).toString() }
                            })}
                        />
                        {errors.idMapping !== undefined && (
                            <FormErrorMessage as='span'>{errors.idMapping.message}</FormErrorMessage>
                        )}
                    </FormControl>
                    <Flex
                        direction={{
                            base: 'column',
                            lg: 'row'
                        }}
                        mb='1rem'
                    >
                        <FormControl
                            id='userName'
                            isInvalid={errors.userName !== undefined}
                            isRequired
                            mb={{
                                base: '1rem',
                                lg: '0'
                            }}
                            mr={{
                                base: 0,
                                lg: '1rem'
                            }}
                        >
                            <FormLabel>{t('userName')}</FormLabel>
                            <Input
                                variant='filled'
                                {...register('userName', {
                                    required: t('userNameRequired').toString(),
                                    pattern: {
                                        value: /^[a-z0-9]+$/i,
                                        message: t('userNameShouldOnlyContainLettersAndOrNumbers').toString()
                                    }
                                })}
                            />
                            {errors.userName !== undefined && (
                                <FormErrorMessage as='span'>{errors.userName.message}</FormErrorMessage>
                            )}
                        </FormControl>
                        <FormControl
                            id='email'
                            isInvalid={errors.email !== undefined}
                        >
                            <FormLabel>{t('email')}</FormLabel>
                            <Input
                                variant='filled'
                                {...register('email', {
                                    pattern: {
                                        value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i,
                                        message: t('invalidEmail').toString()
                                    }
                                })}
                            />
                            {errors.email !== undefined && (
                                <FormErrorMessage as='span'>{errors.email.message}</FormErrorMessage>
                            )}
                        </FormControl>
                    </Flex>
                    <Flex
                        direction={{
                            base: 'column',
                            lg: 'row'
                        }}
                        mb='1rem'
                    >
                        <FormControl
                            id='password'
                            isInvalid={errors.password !== undefined}
                            isRequired={isCreationMode}
                            mb={{
                                base: '1rem',
                                lg: '0'
                            }}
                            mr={{
                                base: 0,
                                lg: '1rem'
                            }}
                        >
                            <FormLabel>{t('password')}</FormLabel>
                            <Input
                                type='password'
                                variant='filled'
                                {...register('password', {
                                    minLength: { value: 8, message: t('passwordMinimumLength', { length: 8 }).toString() },
                                    validate: value => {
                                        if (isCreationMode && isNullOrWhiteSpace(value)) {
                                            return t('passwordRequired').toString();
                                        }
                                        return true;
                                    }
                                })}
                            />
                            {errors.password !== undefined && (
                                <FormErrorMessage as='span'>{errors.password.message}</FormErrorMessage>
                            )}
                        </FormControl>
                        <FormControl
                            id='confirmPassword'
                            isInvalid={errors.confirmPassword !== undefined}
                            isRequired={isCreationMode}
                        >
                            <FormLabel>{t('confirmPassword')}</FormLabel>
                            <Input
                                type='password'
                                variant='filled'
                                {...register('confirmPassword', {
                                    validate: value => {
                                        if (isCreationMode && isNullOrWhiteSpace(value)) {
                                            return t('passwordRequired').toString();
                                        }
                                        if (value !== getValues('password')) {
                                            return t('passwordsDoesNotMatch').toString();
                                        }
                                        return true;
                                    }
                                })}
                            />
                            {errors.confirmPassword !== undefined && (
                                <FormErrorMessage as='span'>{errors.confirmPassword.message}</FormErrorMessage>
                            )}
                        </FormControl>
                    </Flex>
                    <Flex
                        direction={{
                            base: 'column',
                            lg: 'row'
                        }}
                    >
                        <FormControl
                            id='firstName'
                            isInvalid={errors.firstName !== undefined}
                            isRequired
                            mb={{
                                base: '1rem',
                                lg: '0'
                            }}
                            mr={{
                                base: 0,
                                lg: '1rem'
                            }}
                        >
                            <FormLabel>{t('firstName')}</FormLabel>
                            <Input
                                variant='filled'
                                {...register('firstName', {
                                    required: t('firstNameRequired').toString(),
                                    maxLength: { value: 100, message: t('firstNameMaximumLength', { length: 100 }).toString() }
                                })}
                            />
                            {errors.firstName !== undefined && (
                                <FormErrorMessage as='span'>{errors.firstName.message}</FormErrorMessage>
                            )}
                        </FormControl>
                        <FormControl
                            id='lastName'
                            isInvalid={errors.lastName !== undefined}
                            isRequired
                        >
                            <FormLabel>{t('lastName')}</FormLabel>
                            <Input
                                variant='filled'
                                {...register('lastName', {
                                    required: t('lastNameRequired').toString(),
                                    maxLength: { value: 100, message: t('lastNameMaximumLength', { length: 100 }).toString() }
                                })}
                            />
                            {errors.lastName !== undefined && (
                                <FormErrorMessage as='span'>{errors.lastName.message}</FormErrorMessage>
                            )}
                        </FormControl>
                    </Flex>
                </Flex>
                <FormControl
                    as='fieldset'
                    flex='1'
                    id='workDays'
                    isInvalid={errors.workDays !== undefined}
                    isRequired
                    maxWidth={{
                        lg: '25%'
                    }}
                >
                    <FormLabel as='legend'>{t('workDays')}</FormLabel>
                    <VStack
                        alignItems='flex-start'
                        spacing='.5rem'
                    >
                        {
                            daysOfWeek.map((dayOfWeek) => {
                                return (
                                    <Checkbox
                                        key={dayOfWeek}
                                        defaultChecked={isWorkDayDefaultChecked(dayOfWeek)}
                                        value={dayOfWeek}
                                        {...register('workDays', {
                                            validate: value => {
                                                if (!anyElement(value)) {
                                                    return t('atLeastOneWorkDayIsRequired').toString();
                                                }
                                                return true;
                                            }
                                        })}
                                    >
                                        {dayjs.weekdays()[dayOfWeek]}
                                    </Checkbox>
                                );
                            })
                        }
                    </VStack>
                    {errors.workDays !== undefined && (
                        <FormErrorMessage>{errors.workDays.message}</FormErrorMessage>
                    )}
                </FormControl>
            </Flex>
            <Button
                alignSelf={{
                    lg: 'flex-end'
                }}
                colorScheme='blue'
                isDisabled={!isDirty}
                isLoading={isSubmitting}
                leftIcon={<FontAwesomeIcon icon={faCheck} />}
                type='submit'
            >
                {t('validate')}
            </Button>
        </Flex>
    );
};

export default UserForm;