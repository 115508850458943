// Chakra
import {
    Flex,
    HStack,
    IconButton,
    Text,
    Tooltip
} from '@chakra-ui/react';

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faClone,
    faPen,
    faTrashAlt
} from '@fortawesome/pro-light-svg-icons';

// I18next
import {
    Trans,
    useTranslation
} from 'react-i18next';

// Day
import dayjs from 'day';

// Utilities
import { formatTimeFromDate } from 'utilities/timeUtilities';

// View models
import { IActivityForActivityList } from 'viewmodels/Activity/IActivityForActivityList';

interface IActivityListItemProps {
    activity: IActivityForActivityList;
    timeMode?: 'StartAndEnd' | 'Duration';
    editActivityButtonClickHandler: Function;
    duplicateActivityButtonClickHandler: Function;
    deleteActivityButtonClickHandler: Function;
};

const ActivityListItem: React.FC<IActivityListItemProps> = ({
    activity,
    timeMode = 'StartAndEnd',
    editActivityButtonClickHandler,
    duplicateActivityButtonClickHandler,
    deleteActivityButtonClickHandler
}) => {
    const { t } = useTranslation();

    return (
        <Flex
            as='li'
            bgColor='#fff'
            borderRadius='.5rem'
            boxShadow='0px 4px 4px 0px rgba(0, 0, 0, .2)'
            direction='column'
            padding='1rem'
        >
            <Flex
                justifyContent='space-between'
                mb='1rem'
            >
                <Text
                    as='span'
                    mr='1rem'
                >
                    {
                        timeMode !== 'StartAndEnd' ?
                        (
                            <Trans
                                i18nKey='labelComponentColonWithValue'
                                values={{
                                    label: t('duration'),
                                    value: dayjs.duration(dayjs(activity.endDate).diff(activity.startDate)).format('HH:mm')
                                }}
                                components={{
                                    label:
                                        <Text
                                            as='span'
                                            fontWeight='700'
                                        />
                                }}
                            />
                        ) :
                        (
                            <Trans
                                i18nKey='fromTimeToTime'
                                values={{
                                    fromTime: formatTimeFromDate(activity.startDate),
                                    toTime: formatTimeFromDate(activity.endDate)
                                }}
                                components={{
                                    fromTime:
                                        <Text
                                            as='span'
                                            color='blue.500'
                                            fontWeight='700'
                                        />,
                                    toTime:
                                        <Text
                                            as='span'
                                            color='blue.500'
                                            fontWeight='700'
                                        />
                                }}
                            />
                        )
                    }
                </Text>
                <HStack spacing='.5rem'>
                    <Tooltip label={t('editMyActivity')}>
                        <IconButton
                            aria-label={t('editMyActivity')}
                            colorScheme='blue'
                            icon={<FontAwesomeIcon icon={faPen} />}
                            variant='ghost'
                            onClick={() => editActivityButtonClickHandler(activity.id)}
                        />
                    </Tooltip>
                    <Tooltip label={t('duplicateMyActivity')}>
                        <IconButton
                            aria-label={t('duplicateMyActivity')}
                            colorScheme='blue'
                            icon={<FontAwesomeIcon icon={faClone} />}
                            variant='ghost'
                            onClick={() => duplicateActivityButtonClickHandler(activity.id)}
                        />
                    </Tooltip>
                    <Tooltip label={t('deleteMyActivity')}>
                        <IconButton
                            aria-label={t('deleteMyActivity')}
                            colorScheme='blue'
                            icon={<FontAwesomeIcon icon={faTrashAlt} />}
                            variant='ghost'
                            onClick={() => deleteActivityButtonClickHandler(activity.id)}
                        />
                    </Tooltip>
                </HStack>
            </Flex>
            <Text
                as='span'
                mb='1rem'
            >
                <Trans
                    i18nKey='labelComponentColonWithValue'
                    values={{
                        label: t('activityType'),
                        value: activity.activityType.name
                    }}
                    components={{
                        label:
                            <Text
                                as='span'
                                fontWeight='700'
                            />
                    }}
                />
            </Text>
            <Flex
                direction={{
                    base: 'column',
                    lg: 'row'
                }}
            >
                <Flex
                    direction='column'
                    flex='1'
                    mb={{
                        base: '1rem',
                        lg: 0
                    }}
                    mr={{
                        lg: '1rem'
                    }}
                >
                    <Text
                        as='span'
                        fontWeight='700'
                    >
                        {t('task')}
                    </Text>
                    <Text as='span'>{activity.task.name}</Text>
                </Flex>
                <Flex
                    direction='column'
                    flex='1'
                >
                    <Text
                        as='span'
                        fontWeight='700'
                    >
                        {t('customer')}
                    </Text>
                    <Text as='span'>{activity.task.customer.name}</Text>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default ActivityListItem;