// Chakra
import {
    Center,
    Container
} from '@chakra-ui/react';

// I18next
import { useTranslation } from 'react-i18next';

// Router
import {
    Redirect,
    Route,
    Switch
} from 'react-router-dom';

// Helmet
import Helmet from 'react-helmet';

// Containers
import SignInContainer from 'containers/SignInContainer';

const AccountPage = () => {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>{t('signIntoYourAccount')}</title>
            </Helmet>
            <Center flex='1'>
                <Container size='sm'>
                    <Switch>
                        <Route path='/:language/account/sign-in'>
                            <SignInContainer />
                        </Route>
                        <Redirect to='/:language/account/sign-in' />
                    </Switch>
                </Container>
            </Center>
        </>
    );
};

export default AccountPage;