// Chakra
import { chakra } from '@chakra-ui/react';

// Hooks
import useIsComputerScreenSize from 'hooks/useIsComputerScreenSize';

// Layouts
import Appbar from 'layouts/AppBar';
import CondensedAppBar from 'layouts/CondensedAppBar';

interface IHeaderProps {
    isAdministratorMode: boolean;
    signOutHandler: Function;
};

const Header: React.FC<IHeaderProps> = ({
    isAdministratorMode,
    signOutHandler
}) => {
    const isComputerScreenSize = useIsComputerScreenSize();

    return (
        <chakra.header>
            {
                isComputerScreenSize ?
                (
                    <Appbar
                        isAdministratorMode={isAdministratorMode}
                        signOutHandler={signOutHandler}
                    />
                ) :
                (
                    <CondensedAppBar
                        isAdministratorMode={isAdministratorMode}
                        signOutHandler={signOutHandler}
                    />
                )
            }
        </chakra.header>
    );
};

export default Header;