import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';

import './i18n';

// Chakra
import { ChakraProvider } from '@chakra-ui/react';
import theme from 'styles/theme';

// Redux
import { Provider } from 'react-redux';
import store from './redux/Store';

// Redux persist
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

// Router
import {
  Redirect,
  Route,
  Router,
  Switch
} from 'react-router-dom';
import { createBrowserHistory } from 'history';

// Constants
import { applicationConfiguration } from './applicationConstants';

// Components
import Loader from 'components/Loader';

export const history = createBrowserHistory();

const render = () => {
  const App = require('./App').default;
  let persistor = persistStore(store);
  
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={<Loader />} persistor={persistor}>
        <ChakraProvider theme={theme}>
          <Router history={history}>
            <Switch>
              <Route path={`/:language(${applicationConfiguration.supportedLanguages.join('|')})`}>
                <App />
              </Route>
              <Redirect to={`/${applicationConfiguration.defaultLanguage}`} />
            </Switch>
          </Router>
        </ChakraProvider>
      </PersistGate>
    </Provider>,
    document.getElementById('root')
  )
};

render();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
