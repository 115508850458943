// Chakra
import { VStack } from '@chakra-ui/react';

// View models
import { IActivityForActivityList } from 'viewmodels/Activity/IActivityForActivityList';

// Components
import ActivityListItem from './ActivityListItem';

interface IActivityListProps {
    activities: IActivityForActivityList[];
    timeMode?: 'StartAndEnd' | 'Duration';
    editActivityHandler: Function;
    duplicateActivityHandler: Function;
    deleteActivityHandler: Function;
};

const ActivityList: React.FC<IActivityListProps> = ({
    activities,
    timeMode = 'StartAndEnd',
    editActivityHandler,
    duplicateActivityHandler,
    deleteActivityHandler
}) => {
    return (
        <VStack
            alignItems='stretch'
            as='ul'
            spacing='1rem'
        >
            {
                activities.map((activity) => {
                    return (
                        <ActivityListItem
                            key={activity.id}
                            activity={activity}
                            timeMode={timeMode}
                            editActivityButtonClickHandler={editActivityHandler}
                            duplicateActivityButtonClickHandler={duplicateActivityHandler}
                            deleteActivityButtonClickHandler={deleteActivityHandler}
                        />
                    );
                })
            }
        </VStack>
    );
};

export default ActivityList;