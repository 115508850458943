// Chakra
import {
    chakra,
    Flex,
    Heading,
    Text
} from '@chakra-ui/react';

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIdCard } from '@fortawesome/pro-light-svg-icons';

// I18next
import { useTranslation } from 'react-i18next';

// View models
import { IApplicationUserForUserProfile } from 'viewmodels/ApplicationUser/IApplicationUserForUserProfile';

// Components
import Loader from 'components/Loader';
import UserDetails from 'components/UserDetails';

interface IUserProfileProps {
    user: IApplicationUserForUserProfile | null;
    isFetchingUser: boolean;
    editUserNameHandler: Function;
    editEmailHandler: Function;
    editPasswordHandler: Function;
    editUserDetailsHandler: Function;
};

const UserProfile: React.FC<IUserProfileProps> = ({
    user,
    isFetchingUser,
    editUserNameHandler,
    editEmailHandler,
    editPasswordHandler,
    editUserDetailsHandler
}) => {
    const { t } = useTranslation();

    return (
        <Flex
            bgColor='#fff'
            borderRadius='.5rem'
            boxShadow='0px 4px 4px 0px rgba(0, 0, 0, .2)'
            direction='column'
            padding='2rem'
        >
            <Heading
                as='h1'
                borderBottom='1px solid #f0f0f0'
                fontSize='1.5rem'
                fontWeight='400'
                mb='2rem'
                paddingBottom='1rem'
                textAlign={{
                    base: 'center',
                    lg: 'left'
                }}
            >
                <chakra.svg
                    as={FontAwesomeIcon}
                    icon={faIdCard}
                    mr='1rem'
                />
                {t('userProfile')}
            </Heading>
            <UserDetailsComponent
                user={user}
                isFetchingUser={isFetchingUser}
                editUserNameHandler={editUserNameHandler}
                editEmailHandler={editEmailHandler}
                editPasswordHandler={editPasswordHandler}
                editUserDetailsHandler={editUserDetailsHandler}
            />
        </Flex>
    );
};

interface IUserDetailsComponentProps extends IUserProfileProps {};

const UserDetailsComponent: React.FC<IUserDetailsComponentProps> = ({
    user,
    isFetchingUser,
    editUserNameHandler,
    editEmailHandler,
    editPasswordHandler,
    editUserDetailsHandler
}) => {
    const { t } = useTranslation();

    if (isFetchingUser) {
        return (
            <Loader />
        );
    }

    if (user === null) {
        return (
            <Text as='span'>{t('errors.couldNotFetchYourUserProfile')}</Text>
        );
    }

    return (
        <UserDetails
            user={user}
            editUserNameButtonClickHandler={editUserNameHandler}
            editEmailButtonClickHandler={editEmailHandler}
            editPasswordButtonClickHandler={editPasswordHandler}
            editUserDetailsButtonClickHandler={editUserDetailsHandler}
        />
    );
};

export default UserProfile;