// Redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/Reducer';

// Router
import {
    Redirect,
    Route,
    RouteProps
} from 'react-router-dom';

// Utilities
import { isRoleInJwtToken } from 'utilities/appUtilities';
import { generateLocalizedHref } from 'utilities/localizationUtilities';

interface IAdministratorRouteProps extends RouteProps {
    redirectToPath: string;
};

const AdministratorRoute: React.FC<IAdministratorRouteProps> = ({
    redirectToPath,
    ...routeProps
}) => {
    const {
        token,
        isUserSignedIn
    } = useSelector((state: RootState) => state.user);
    const isAdministrator = (
        token !== null &&
        isUserSignedIn &&
        isRoleInJwtToken(token, "Administrator")
    );

    if (!isAdministrator) {
        return (
            <Redirect to={generateLocalizedHref(redirectToPath)} />
        );
    }
    
    return (
        <Route {...routeProps} />
    );
};

export default AdministratorRoute;