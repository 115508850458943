// Layouts
import Footer from 'layouts/Footer';

const FooterContainer = () => {
    const owner = process.env.REACT_APP_OWNER ?? '';
    const ownerWebsiteUrl = process.env.REACT_APP_OWNER_WEBSITE_URL;

    return (
        <Footer
            owner={owner}
            ownerWebsiteUrl={ownerWebsiteUrl}
        />
    );
};

export default FooterContainer;