// Chakra
import { Container } from '@chakra-ui/react';

// I18next
import { useTranslation } from 'react-i18next';

// Helmet
import Helmet from 'react-helmet';

// Components
import NotFound from 'components/NotFound';

const NotFoundPage = () => {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>{t('pageNotFound')}</title>
            </Helmet>
            <Container size='sm'>
                <NotFound />
            </Container>
        </>
    );
};

export default NotFoundPage;