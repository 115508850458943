// Redux
import {
    createSlice,
    PayloadAction
} from '@reduxjs/toolkit';

interface ICalendarState {
    selectedDateValues: {
        year: number,
        month: number,
        day: number
    };
};

const currentDate = new Date();

const calendarInitialState: ICalendarState = {
    selectedDateValues: {
        year: currentDate.getFullYear(),
        month: currentDate.getMonth(),
        day: currentDate.getDate()
    }
};

const calendarSlice = createSlice({
    name: 'calendar',
    initialState: calendarInitialState,
    reducers: {
        setSelectedDateValues: (state, action: PayloadAction<{
            year: number,
            month: number,
            day: number
        }>) => {
            return {
                ...state,
                selectedDateValues: action.payload
            };
        },
        setSelectedYear: (state, action: PayloadAction<number>) => {
            return {
                ...state,
                selectedDateValues: {
                    ...state.selectedDateValues,
                    year: action.payload
                }
            };
        },
        setSelectedMonth: (state, action: PayloadAction<number>) => {
            return {
                ...state,
                selectedDateValues: {
                    ...state.selectedDateValues,
                    month: action.payload
                }
            };
        },
        setSelectedDay: (state, action: PayloadAction<number>) => {
            return {
                ...state,
                selectedDateValues: {
                    ...state.selectedDateValues,
                    day: action.payload
                }
            };
        }
    }
});

export const {
    setSelectedDateValues,
    setSelectedMonth,
    setSelectedYear,
    setSelectedDay
} = calendarSlice.actions;

export default calendarSlice.reducer;