import {
    useEffect,
    useState
} from 'react';

// Chakra
import {
    useDisclosure,
    useToast
} from '@chakra-ui/react';

// Redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/Reducer';

// Axios
import { AxiosError } from 'axios';

// I18next
import { useTranslation } from 'react-i18next';

// Hook form
import { NestedValue } from 'react-hook-form';

// Utilities
import { isRoleInJwtToken } from 'utilities/appUtilities';

// Services
import AccountService from 'services/AccountService';

// Form values
import { ChangeUserNameFormValues } from 'formValues/ChangeUserNameFormValues';
import { ChangeEmailFormValues } from 'formValues/ChangeEmailFormValues';
import { ChangePasswordFormValues } from 'formValues/ChangePasswordFormValues';
import { DefaultChangeUserDetailsFormValues } from 'formValues/DefaultChangeUserDetailsFormValues';
import { SubmitChangeUserDetailsFormValues } from 'formValues/SubmitChangeUserDetailsFormValues';

// Models
import { IApplicationUserRequestForChangeEmail } from 'models/ApplicationUser/IApplicationUserRequestForChangeEmail';
import { IApplicationUserRequestForChangePassword } from 'models/ApplicationUser/IApplicationUserRequestForChangePassword';
import { IApplicationUserRequestForChangeUserDetails } from 'models/ApplicationUser/IApplicationUserRequestForChangeUserDetails';
import { IApplicationUserRequestForChangeUserName } from 'models/ApplicationUser/IApplicationUserRequestForChangeUserName';

// View models
import { IApplicationUserForUserProfile } from 'viewmodels/ApplicationUser/IApplicationUserForUserProfile';

// Components
import ChangeEmailForm from 'components/ChangeEmailForm';
import ChangePasswordForm from 'components/ChangePasswordForm';
import ChangeUserDetailsForm from 'components/ChangeUserDetailsForm';
import ChangeUserNameForm from 'components/ChangeUserNameForm';
import TitledModal from 'components/modals/TitledModal';
import UserProfile from 'components/UserProfile';

const UserProfileContainer = () => {
    const toast = useToast();
    const { t } = useTranslation();
    const { token } = useSelector((state: RootState) => state.user);
    const {
        isOpen: isOpenChangeUserNameModal,
        onOpen: onOpenChangeUserNameModal,
        onClose: onCloseChangeUserNameModal
    } = useDisclosure();
    const {
        isOpen: isOpenChangeEmailModal,
        onOpen: onOpenChangeEmailModal,
        onClose: onCloseChangeEmailModal
    } = useDisclosure();
    const {
        isOpen: isOpenChangePasswordModal,
        onOpen: onOpenChangePasswordModal,
        onClose: onCloseChangePasswordModal
    } = useDisclosure();
    const {
        isOpen: isOpenChangeUserDetailsModal,
        onOpen: onOpenChangeUserDetailsModal,
        onClose: onCloseChangeUserDetailsModal
    } = useDisclosure();
    const [isFetchingUser, setIsFetchingUser] = useState<boolean>(false);
    const [isSubmittingUserName, setIsSubmittingUserName] = useState<boolean>(false);
    const [isSubmittingEmail, setIsSubmittingEmail] = useState<boolean>(false);
    const [isSubmittingPassword, setIsSubmittingPassword] = useState<boolean>(false);
    const [isSubmittingUserDetails, setIsSubmittingUserDetails] = useState<boolean>(false);
    const [user, setUser] = useState<IApplicationUserForUserProfile | null>(null);
    const isUserAdministrator = token !== null ? isRoleInJwtToken(token, "Administrator") : false;

    useEffect(() => {
        setIsFetchingUser(true);

        AccountService.fetchMyApplicationUserForUserProfile()
            .then((response) => {
                setUser(response);
            })
            .catch((error: AxiosError) => {
                if (!error.response) {
                    return;
                }

                toast({
                    title: t('anErrorHasOccurred'),
                    description: t('errors.couldNotFetchYourUserProfile'),
                    status: 'error',
                    duration: 5000,
                    isClosable: true
                });
            })
            .finally(() => {
                setIsFetchingUser(false)
            });
    }, []);

    const getDefaultChangeUserDetailsFormValues = () => {
        if (user === null) return undefined;

        const formValues: DefaultChangeUserDetailsFormValues = {
            firstName: user.firstName,
            lastName: user.lastName,
            workDays: user.workDays.map((workDay) => workDay.toString()) as NestedValue<string[]>
        };

        return formValues;
    };

    const handleUserNameSubmit = (model: ChangeUserNameFormValues) => {
        setIsSubmittingUserName(true);

        AccountService.changeUserNameOfCurrentUser(model as IApplicationUserRequestForChangeUserName)
            .then(() => {
                setUser(previousState => {
                    return {
                        ...previousState!,
                        userName: model.userName
                    };
                });
            })
            .catch((error: AxiosError) => {
                if (!error.response || !error.response.data.code) {
                    return;
                }

                let description = t('errors.couldNotUpdateYourUserName');

                switch (error.response.data.code) {
                    case 'INVALID_CREDENTIALS':
                        description = t('invalidCredentials');
                        break;
                }

                toast({
                    title: t('anErrorHasOccurred'),
                    description: description,
                    status: 'error',
                    duration: 5000,
                    isClosable: true
                });
            })
            .finally(() => {
                setIsSubmittingUserName(false);
                onCloseChangeUserNameModal();
            });
    };

    const handleEmailSubmit = (model: ChangeEmailFormValues) => {
        setIsSubmittingEmail(true);

        AccountService.changeEmailOfCurrentUser(model as IApplicationUserRequestForChangeEmail)
            .then(() => {
                setUser(previousState => {
                    return {
                        ...previousState!,
                        email: model.email
                    };
                });
            })
            .catch((error: AxiosError) => {
                if (!error.response || !error.response.data.code) {
                    return;
                }

                let description = t('errors.couldNotUpdateYourEmail');

                switch (error.response.data.code) {
                    case 'INVALID_CREDENTIALS':
                        description = t('invalidCredentials');
                        break;
                }

                toast({
                    title: t('anErrorHasOccurred'),
                    description: description,
                    status: 'error',
                    duration: 5000,
                    isClosable: true
                });
            })
            .finally(() => {
                setIsSubmittingEmail(false);
                onCloseChangeEmailModal();
            });
    };

    const handlePasswordSubmit = (model: ChangePasswordFormValues) => {
        setIsSubmittingPassword(true);

        AccountService.changePasswordOfCurrentUser(model as IApplicationUserRequestForChangePassword)
            .catch((error: AxiosError) => {
                if (!error.response || !error.response.data.code) {
                    return;
                }

                let description = t('errors.couldNotUpdateYourPassword');

                switch (error.response.data.code) {
                    case 'INVALID_CREDENTIALS':
                        description = t('invalidCredentials');
                        break;
                }

                toast({
                    title: t('anErrorHasOccurred'),
                    description: description,
                    status: 'error',
                    duration: 5000,
                    isClosable: true
                });
            })
            .finally(() => {
                setIsSubmittingPassword(false);
                onCloseChangePasswordModal();
            });
    };

    const handleUserDetailsSubmit = (model: SubmitChangeUserDetailsFormValues) => {
        const apiModel: IApplicationUserRequestForChangeUserDetails = {
            firstName: model.firstName,
            lastName: model.lastName,
            workDays: model.workDays.map((workDay) => Number(workDay))
        };

        setIsSubmittingUserDetails(true);

        AccountService.changeUserDetailsOfCurrentUser(apiModel)
            .then(() => {
                setUser(previousState => {
                    return {
                        ...previousState!,
                        firstName: apiModel.firstName,
                        lastName: apiModel.lastName,
                        workDays: apiModel.workDays
                    };
                });
            })
            .catch((error: AxiosError) => {
                if (!error.response) {
                    return;
                }
                
                toast({
                    title: t('anErrorHasOccurred'),
                    description: t('errors.couldNotUpdateYourUserDetails'),
                    status: 'error',
                    duration: 5000,
                    isClosable: true
                });
            })
            .finally(() => {
                setIsSubmittingUserDetails(false);
                onCloseChangeUserDetailsModal();
            });
    };

    return (
        <>
            <UserProfile
                user={user}
                isFetchingUser={isFetchingUser}
                editUserNameHandler={onOpenChangeUserNameModal}
                editEmailHandler={onOpenChangeEmailModal}
                editPasswordHandler={onOpenChangePasswordModal}
                editUserDetailsHandler={onOpenChangeUserDetailsModal}
            />
            <TitledModal
                title={t('editMyUserName')}
                isOpen={isOpenChangeUserNameModal}
                size='sm'
                closeModalHandler={onCloseChangeUserNameModal}
            >
                <ChangeUserNameForm
                    isSubmitting={isSubmittingUserName}
                    submitHandler={handleUserNameSubmit}
                />
            </TitledModal>
            <TitledModal
                title={t('editMyEmail')}
                isOpen={isOpenChangeEmailModal}
                size='sm'
                closeModalHandler={onCloseChangeEmailModal}
            >
                <ChangeEmailForm
                    isSubmitting={isSubmittingEmail}
                    submitHandler={handleEmailSubmit}
                />
            </TitledModal>
            <TitledModal
                title={t('editMyPassword')}
                isOpen={isOpenChangePasswordModal}
                size='sm'
                closeModalHandler={onCloseChangePasswordModal}
            >
                <ChangePasswordForm
                    isSubmitting={isSubmittingPassword}
                    submitHandler={handlePasswordSubmit}
                />
            </TitledModal>
            <TitledModal
                title={t('editMyDetails')}
                isOpen={isOpenChangeUserDetailsModal}
                closeModalHandler={onCloseChangeUserDetailsModal}
            >
                <ChangeUserDetailsForm
                    formValues={getDefaultChangeUserDetailsFormValues()}
                    isUserAdministrator={isUserAdministrator}
                    isSubmitting={isSubmittingUserDetails}
                    submitHandler={handleUserDetailsSubmit}
                />
            </TitledModal>
        </>
    );
};

export default UserProfileContainer;