export const screenBreakpoints = {
    phoneLandscapeBreakpoint: 576,
    tabletBreakpoint: 768,
    tabletLandscapeBreakpoint: 992,
    mediumComputerBreakpoint: 1200,
    largeComputerBreakpoint: 1400
};

export const containerMaximumWidths = {
    phoneLandscapeMaximumWidth: 540,
    tabletMaximumWidth: 720,
    tabletLandscapeMaximumWidth: 960,
    mediumComputerMaximumWidth: 1140,
    largeComputerMaximumWidth: 1320
};

interface IApplicationConfigurationProps {
    supportedLanguages: string[];
    defaultLanguage: string;
};

export const applicationConfiguration: IApplicationConfigurationProps = {
    supportedLanguages: ['fr'],
    defaultLanguage: 'fr'
};

interface IActivityConfigurationProps {
    timeMode: 'StartAndEnd' | 'Duration';
};

export const activityConfiguration: IActivityConfigurationProps = {
    timeMode: 'Duration'
};