// Day
import dayjs from 'day';

export function formatTimeFromIso8601(iso8601: string, format: string = 'HH:mm') {
    return dayjs.duration(dayjs.duration(iso8601).asMilliseconds()).format(format);
}

export function formatTimeFromDate(date: Date, format: string = 'HH:mm') {
    return dayjs(date).format(format);
}

export function formatTimeFromMilliseconds(milliseconds: number, format: string = 'HH:mm') {
    return dayjs.duration(milliseconds).format(format);
}