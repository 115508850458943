// Day
import dayjs from 'day';

export function daysInMonth(year: number, month: number) {
    return new Date(year, month + 1, 0).getDate(); // Javascript month based on 0
};

export function isSameDate(date1: Date, date2: Date) {
    return dayjs(date1).isSame(date2, 'day');
};

export function isEarlierDate(date1: Date, date2: Date) {
    return dayjs(date1).isBefore(date2, 'day');
};

export function isSameOrEarlierDate(date1: Date, date2: Date) {
    return dayjs(date1).isSameOrBefore(date2, 'day');
};

export function isLaterDate(date1: Date, date2: Date) {
    return dayjs(date1).isAfter(date2, 'day');
};

export function isSameOrLaterDate(date1: Date, date2: Date) {
    return dayjs(date1).isSameOrAfter(date2, 'day');
};

export function formatDate(date: Date, format: string = 'DD/MM/YYYY') {
    return dayjs(date).format(format);
};

export function getPreviousDayOfWeek(date: Date, dayOfWeek: number) {
    date.setDate(date.getDate() + (dayOfWeek - 7 - date.getDay() + 1) % 7 - 1);
    return date;
};

export function getNextDayOfWeek(date: Date, dayOfWeek: number) {
    date.setDate(date.getDate() + (dayOfWeek + 7 - date.getDay() - 1) % 7 + 1);
    return date;
};

export function getRangeOfYears(currentYear: number, numberOfPreviousYears: number = 0, numberOfNextYears: number = 0) {
    return [
        ...Array.from({ length: numberOfPreviousYears }, (_, index) => currentYear - (index + 1)),
        currentYear,
        ...Array.from({ length: numberOfNextYears }, (_, index) => currentYear + (index + 1))
    ].sort((a, b) => a - b);
};