// Chakra
import {
    Box,
    Flex,
    Heading,
    Image,
    Text
} from '@chakra-ui/react';

// I18next
import { useTranslation } from 'react-i18next';

// Components
import SignInForm from 'components/SignInForm';

interface ISignInProps {
    isSigningIn: boolean;
    signInHandler: Function;
};

const SignIn: React.FC<ISignInProps> = ({
    isSigningIn,
    signInHandler
}) => {
    const { t } = useTranslation();

    return (
        <Flex
            bgColor='#fff'
            borderRadius='.5rem'
            boxShadow='0px 4px 4px 0px rgba(0, 0, 0, .2)'
            direction='column'
            p='2rem'
        >
            <Box
                height='100px'
                mb='1rem'
                maxHeight='100px'
                overflow='hidden'
            >
                <Image
                    alt='Logo'
                    margin='0 auto'
                    maxHeight='100%'
                    maxWidth='100%'
                    objectFit='contain'
                    src='/images/logo.png'
                />
            </Box>
            <Heading
                as='h1'
                color='blue.500'
                fontSize='1.5rem'
                mb='1rem'
                textAlign='center'
            >
                {t('welcome')}
            </Heading>
            <Text
                as='span'
                color='#77798c'
                mb='2rem'
                textAlign='center'
            >
                {t('components.signIn.subtitle')}
            </Text>
            <SignInForm
                isSubmitting={isSigningIn}
                submitHandler={signInHandler}
            />
        </Flex>
    );
};

export default SignIn;