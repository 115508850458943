// Chakra
import {
    Flex,
    HStack,
    IconButton,
    Image,
    Link as ChakraLink,
    Tooltip,
    useDisclosure
} from '@chakra-ui/react';

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBars,
    faCalendarAlt,
    faUser,
    faUsers
} from '@fortawesome/pro-regular-svg-icons';

// I18next
import { useTranslation } from 'react-i18next';

// Router
import { Link } from 'react-router-dom';

// Utilities
import { generateLocalizedHref } from 'utilities/localizationUtilities';

// Layouts
import MenuDrawer from 'layouts/MenuDrawer';

interface ICondensedAppBarProps {
    isAdministratorMode: boolean;
    signOutHandler: Function;
};

const CondensedAppBar: React.FC<ICondensedAppBarProps> = ({
    isAdministratorMode,
    signOutHandler
}) => {
    const { t } = useTranslation();
    const {
        isOpen,
        onOpen,
        onClose
    } = useDisclosure();

    return (
        <>
            <Flex
                bgColor='#fff'
                boxShadow='0px 4px 4px 0px rgba(0, 0, 0, .2)'
                justifyContent='space-between'
                p='.5rem 1rem'
            >
                <Flex
                    alignItems='center'
                    mr='1rem'
                >
                    <Tooltip label={t('menu')}>
                        <IconButton
                            aria-label={t('menu')}
                            colorScheme='blue'
                            icon={<FontAwesomeIcon icon={faBars} />}
                            mr='1rem'
                            variant='ghost'
                            onClick={onOpen}
                        />
                    </Tooltip>
                    <ChakraLink
                        as={Link}
                        height='50px'
                        maxHeight='50px'
                        overflow='hidden'
                        to={generateLocalizedHref('/')}
                    >
                        <Image
                            alt='Logo'
                            margin='0 auto'
                            maxHeight='100%'
                            maxWidth='100%'
                            objectFit='contain'
                            src='/images/logo.png'
                        />
                    </ChakraLink>
                </Flex>
                <HStack spacing='.5rem'>
                    <Tooltip label={t('activitiesSummary')}>
                        <IconButton
                            aria-label={t('activitiesSummary')}
                            as={Link}
                            colorScheme='blue'
                            icon={<FontAwesomeIcon icon={faCalendarAlt} />}
                            to={generateLocalizedHref('/')}
                            variant='ghost'
                        />
                    </Tooltip>
                    {isAdministratorMode && (
                        <Tooltip label={t('userManagement')}>
                            <IconButton
                                aria-label={t('userManagement')}
                                as={Link}
                                colorScheme='blue'
                                icon={<FontAwesomeIcon icon={faUsers} />}
                                to={generateLocalizedHref('/users')}
                                variant='ghost'
                            />
                        </Tooltip>
                    )}
                    <Tooltip label={t('myAccount')}>
                        <IconButton
                            aria-label={t('myAccount')}
                            as={Link}
                            colorScheme='blue'
                            icon={<FontAwesomeIcon icon={faUser} />}
                            to={generateLocalizedHref('/my-account')}
                            variant='ghost'
                        />
                    </Tooltip>
                </HStack>
            </Flex>
            <MenuDrawer
                isAdministratorMode={isAdministratorMode}
                isOpen={isOpen}
                signOutHandler={signOutHandler}
                closeHandler={onClose}
            />
        </>
    );
};

export default CondensedAppBar;