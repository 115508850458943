// Redux
import {
    createSlice,
    createAsyncThunk,
    PayloadAction
} from '@reduxjs/toolkit';

// Axios
import { AxiosError } from 'axios';

// Services
import AccountService from 'services/AccountService';

// Models
import { IApplicationUserRequestForSignIn } from 'models/ApplicationUser/IApplicationUserRequestForSignIn';

interface IUserState {
    token: string | null;
    isUserSignedIn: boolean;
    isUserSigningIn: boolean;
};

const userInitialState: IUserState = {
    token: null,
    isUserSignedIn: false,
    isUserSigningIn: false
};

export const signIn = createAsyncThunk(
    'user/signIn',
    async (model: IApplicationUserRequestForSignIn, { rejectWithValue }) => {
        try {
            return await AccountService.signIn(model);
        }
        catch (err) {
            let error: AxiosError = err;
            if (error !== undefined && error !== null) {
                return rejectWithValue(err.response);
            }
            throw err;
        }
    }
);

const userSlice = createSlice({
    name: 'user',
    initialState: userInitialState,
    reducers: {
        setIsUserSigningIn: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isUserSigningIn: action.payload
            };
        },
        signOut: (state) => {
            return {
                ...state,
                token: null,
                isUserSignedIn: false
            };
        }
    },
    extraReducers: builder => {
        builder.addCase(signIn.pending, (state) => {
            state.isUserSigningIn = true;
        });
        builder.addCase(signIn.fulfilled, (state, { payload }) => {
            state.token = payload.token;
            state.isUserSignedIn = true;
            state.isUserSigningIn = false;
        });
        builder.addCase(signIn.rejected, (state, { payload }) => {
            state.isUserSigningIn = false;
        });
    }
});

export const { signOut } = userSlice.actions;

export default userSlice.reducer;