// Chakra
import {
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerOverlay,
    Flex,
    Heading,
    Link as ChakraLink,
    VStack
} from '@chakra-ui/react';

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOut } from '@fortawesome/pro-light-svg-icons';

// I18next
import { useTranslation } from 'react-i18next';

// Router
import { NavLink } from 'react-router-dom';

// Utilities
import { generateLocalizedHref } from 'utilities/localizationUtilities';

interface IMenuDrawerProps {
    isAdministratorMode: boolean;
    isOpen: boolean;
    signOutHandler: Function;
    closeHandler: Function;
};

const MenuDrawer: React.FC<IMenuDrawerProps> = ({
    isAdministratorMode,
    isOpen,
    signOutHandler,
    closeHandler
}) => {
    const { t } = useTranslation();

    return (
        <Drawer
            isOpen={isOpen}
            placement='left'
            onClose={() => closeHandler()}
        >
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerBody>
                    <VStack
                        alignItems='stretch'
                        spacing='2rem'
                    >
                        <MenuItemGroup label={t('navigation')}>
                            <ChakraLink
                                as={NavLink}
                                to={generateLocalizedHref('/')}
                            >
                                {t('activitiesSummary')}
                            </ChakraLink>
                            {isAdministratorMode && (
                                <ChakraLink
                                    as={NavLink}
                                    to={generateLocalizedHref('/users')}
                                >
                                    {t('userManagement')}
                                </ChakraLink>
                            )}
                        </MenuItemGroup>
                        <MenuItemGroup label={t('account')}>
                            <ChakraLink
                                as={NavLink}
                                to={generateLocalizedHref('/my-account/user-profile')}
                            >
                                {t('myUserProfile')}
                            </ChakraLink>
                            <Button
                                colorScheme='blue'
                                isFullWidth
                                leftIcon={<FontAwesomeIcon icon={faSignOut} />}
                                onClick={() => signOutHandler()}
                            >
                                {t('signOut')}
                            </Button>
                        </MenuItemGroup>
                    </VStack>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};

interface IMenuItemGroupProps {
    label: string;
};

const MenuItemGroup: React.FC<IMenuItemGroupProps> = ({
    label,
    children
}) => {
    return (
        <Flex direction='column'>
            <MenuItemHeading label={label} />
            <VStack
                alignItems='flex-start'
                spacing='1rem'
            >
                {children}
            </VStack>
        </Flex>
    );
};

interface IMenuItemHeadingProps extends IMenuItemGroupProps {};

const MenuItemHeading: React.FC<IMenuItemHeadingProps> = ({ label }) => {
    return (
        <Heading
            as='h4'
            fontSize='1.25rem'
            mb='1rem'
            textTransform='uppercase'
        >
            {label}
        </Heading>
    );
};

export default MenuDrawer;