import {
    useEffect,
    useState
} from 'react';

// Chakra
import { useToast } from '@chakra-ui/react';

// Redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/Store';
import { RootState } from 'redux/Reducer';
import {
    setSelectedDay,
    setSelectedMonth,
    setSelectedYear
} from 'redux/slices/CalendarSlice';

// I18next
import { useTranslation } from 'react-i18next';

// Router
import { useHistory } from 'react-router-dom';

// Utilities
import { anyElement } from 'utilities/arrayUtilities';
import { getWorkDaysFromJwtToken } from 'utilities/appUtilities';
import { getRangeOfYears } from 'utilities/dateUtilities';
import { generateLocalizedHref } from 'utilities/localizationUtilities';

// Services
import ActivityService from 'services/ActivityService';

// Models
import { IActivityResponseForTotalWorkTimePerDay } from 'models/Activity/IActivityResponseForTotalWorkTimePerDay';

// Components
import Calendar from 'components/Calendar';
import Loader from 'components/Loader';

const CalendarContainer = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const toast = useToast();
    const { t } = useTranslation();
    const { configuration } = useSelector((state: RootState) => state.configuration);
    const { token } = useSelector((state: RootState) => state.user);
    const { selectedDateValues } = useSelector((state: RootState) => state.calendar);
    const [isTotalWorkTimesPerDayFetching, setIsTotalWorkTimesPerDayFetching] = useState<boolean>(false);
    const [totalWorkTimesPerDay, setTotalWorkTimesPerDay] = useState<IActivityResponseForTotalWorkTimePerDay[]>([]);
    const years = getRangeOfYears(new Date().getFullYear(), 3, 1);
    const workDays = token !== null ? getWorkDaysFromJwtToken(token) : undefined;

    useEffect(() => {
        const urlParameters = new URLSearchParams();

        urlParameters.append('year', selectedDateValues.year.toString());
        urlParameters.append('month', (selectedDateValues.month + 1).toString());

        setIsTotalWorkTimesPerDayFetching(true);

        ActivityService.fetchMyActivitiesForTotalWorkTimePerDay(urlParameters)
            .then((response) => {
                setTotalWorkTimesPerDay(response);
            })
            .catch(() => {
                toast({
                    title: t('anErrorHasOccurred'),
                    description: t('errors.couldNotFetchYourTotalWorkTimesPerDay'),
                    status: 'error',
                    duration: 5000,
                    isClosable: true
                });
            })
            .finally(() => {
                setIsTotalWorkTimesPerDayFetching(false);
            });
    }, [selectedDateValues]);

    const getRestDays = () => {
        if (workDays === undefined || !anyElement(workDays)) {
            return undefined;
        }

        return [0, 1, 2, 3, 4, 5, 6].filter(x => !workDays.includes(x));
    };

    const handleSelectMonth = (event: React.ChangeEvent<HTMLSelectElement>) => {
        dispatch(setSelectedMonth(Number(event.target.value)));
    };

    const handleSelectYear = (event: React.ChangeEvent<HTMLSelectElement>) => {
        dispatch(setSelectedYear(Number(event.target.value)));
    };

    const handleSelectDay = (day: number) => {
        if (
            workDays === undefined ||
            !anyElement(workDays) ||
            !workDays.includes(new Date(selectedDateValues.year, selectedDateValues.month, day).getDay())
        ) {
            return;
        }
        
        dispatch(setSelectedDay(day));
        history.push(generateLocalizedHref('/my-activities'));
    };

    if (isTotalWorkTimesPerDayFetching) {
        return (
            <Loader />
        );
    }
    
    return (
        <Calendar
            years={years}
            totalWorkTimesPerDay={totalWorkTimesPerDay}
            iso8601ValidWorkTime={configuration?.iso8601MaximumWorkTimeAllowed ?? undefined}
            disabledDaysOfWeek={getRestDays()}
            selectedYear={selectedDateValues.year}
            selectedMonth={selectedDateValues.month}
            selectMonthHandler={handleSelectMonth}
            selectYearHandler={handleSelectYear}
            selectDayHandler={handleSelectDay}
        />
    );
};

export default CalendarContainer;