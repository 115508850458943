import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

// Day
import dayjs from 'day';

// Constants
import { applicationConfiguration } from './applicationConstants';

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: applicationConfiguration.defaultLanguage,
        debug: false,
        supportedLngs: applicationConfiguration.supportedLanguages,
        detection: {
            order: ['path']
        },
        interpolation: {
            escapeValue: false
        }
    });

i18n.on('languageChanged', (language) => {
    dayjs.locale(language);
});

export default i18n;