// Chakra
import {
    Button,
    Checkbox,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    VStack
} from '@chakra-ui/react';

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-light-svg-icons';

// I18next
import { useTranslation } from 'react-i18next';

// Hook form
import { useForm } from 'react-hook-form';

// Day
import dayjs from 'day';

// Utilities
import { anyElement } from 'utilities/arrayUtilities';

// Form values
import { DefaultChangeUserDetailsFormValues } from 'formValues/DefaultChangeUserDetailsFormValues';
import { SubmitChangeUserDetailsFormValues } from 'formValues/SubmitChangeUserDetailsFormValues';

interface IChangeUserDetailsFormProps {
    formValues?: DefaultChangeUserDetailsFormValues;
    isUserAdministrator: boolean;
    isSubmitting: boolean;
    submitHandler: Function;
};

const ChangeUserDetailsForm: React.FC<IChangeUserDetailsFormProps> = ({
    formValues,
    isUserAdministrator,
    isSubmitting,
    submitHandler
}) => {
    const { t } = useTranslation();
    const {
        register,
        handleSubmit,
        formState: {
            isDirty,
            errors
        }
    } = useForm<DefaultChangeUserDetailsFormValues>({
        defaultValues: {
            firstName: formValues?.firstName ?? '',
            lastName: formValues?.lastName ?? ''
            /*
                Hook form version: 7.12.2
                useForm defaultValues object does not work for multiple checkboxes,
                it seems like checkboxes are checked when they should not
                Quick fix: use defaultChecked on Checkbox component
            */
            //workDays: formValues?.workDays ?? [],
        }
    });
    const daysOfWeek = [1, 2, 3, 4, 5, 6, 0];

    // Quick fix (please take a look at useForm defaultValues object)
    const isWorkDayDefaultChecked = (dayOfWeek: number) => {
        if (formValues === undefined) {
            return false;
        }
        return formValues.workDays.includes(dayOfWeek.toString());
    };

    const handleSubmitForm = (model: SubmitChangeUserDetailsFormValues) => {
        if (!isDirty || isSubmitting) return;
        // Quick fix (please take a look at useForm defaultValues object)
        const formModel: SubmitChangeUserDetailsFormValues = {
            firstName: model.firstName,
            lastName: model.lastName,
            workDays: []
        };
        if (!isUserAdministrator) {
            // Return work days of user before edition, because form does not submit non registered form components
            formModel.workDays = formValues !== undefined ? formValues.workDays : [];
        } else {
            formModel.workDays = model.workDays;
        }
        submitHandler(formModel);
    };

    return (
        <Flex
            as='form'
            direction='column'
            onSubmit={handleSubmit(handleSubmitForm)}
        >
            <Flex
                direction={{
                    base: 'column',
                    lg: 'row'
                }}
                mb='1rem'
            >
                <FormControl
                    id='firstName'
                    isInvalid={errors.firstName !== undefined}
                    isRequired
                    mb={{
                        base: '1rem',
                        lg: 0
                    }}
                    mr={{
                        lg: '1rem'
                    }}
                >
                    <FormLabel>{t('firstName')}</FormLabel>
                    <Input
                        variant='filled'
                        {...register('firstName', {
                            required: t('firstNameRequired').toString(),
                            maxLength: { value: 100, message: t('firstNameMaximumLength', { length: 100 }).toString() }
                        })}
                    />
                    {errors.firstName !== undefined && (
                        <FormErrorMessage as='span'>{errors.firstName.message}</FormErrorMessage>
                    )}
                </FormControl>
                <FormControl
                    id='lastName'
                    isInvalid={errors.lastName !== undefined}
                    isRequired
                >
                    <FormLabel>{t('lastName')}</FormLabel>
                    <Input
                        variant='filled'
                        {...register('lastName', {
                            required: t('lastNameRequired').toString(),
                            maxLength: { value: 100, message: t('lastNameMaximumLength', { length: 100 }).toString() }
                        })}
                    />
                    {errors.lastName !== undefined && (
                        <FormErrorMessage as='span'>{errors.lastName.message}</FormErrorMessage>
                    )}
                </FormControl>
            </Flex>
            {isUserAdministrator && (
                <FormControl
                    as='fieldset'
                    flex='1'
                    id='workDays'
                    isInvalid={errors.workDays !== undefined}
                    isRequired
                    mb='1rem'
                >
                    <FormLabel as='legend'>{t('workDays')}</FormLabel>
                    <VStack
                        alignItems='flex-start'
                        spacing='.5rem'
                    >
                        {
                            daysOfWeek.map((dayOfWeek) => {
                                return (
                                    <Checkbox
                                        key={dayOfWeek}
                                        defaultChecked={isWorkDayDefaultChecked(dayOfWeek)}
                                        value={dayOfWeek}
                                        {...register('workDays', {
                                            validate: value => {
                                                if (!anyElement(value)) {
                                                    return t('atLeastOneWorkDayIsRequired').toString();
                                                }
                                                return true;
                                            }
                                        })}
                                    >
                                        {dayjs.weekdays()[dayOfWeek]}
                                    </Checkbox>
                                );
                            })
                        }
                    </VStack>
                    {errors.workDays !== undefined && (
                        <FormErrorMessage>{errors.workDays.message}</FormErrorMessage>
                    )}
                </FormControl>
            )}
            <Button
                colorScheme='blue'
                isDisabled={!isDirty}
                isLoading={isSubmitting}
                leftIcon={<FontAwesomeIcon icon={faCheck} />}
                type='submit'
            >
                {t('validate')}
            </Button>
        </Flex>
    );
};

export default ChangeUserDetailsForm;