// Chakra
import { Container } from '@chakra-ui/react';

// I18next
import { useTranslation } from 'react-i18next';

// Helmet
import Helmet from 'react-helmet';

// Containers
import UsersContainer from 'containers/UsersContainer';

const UserPage = () => {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>{t('userManagement')}</title>
            </Helmet>
            <Container size='sm'>
                <UsersContainer />
            </Container>
        </>
    );
};

export default UserPage;