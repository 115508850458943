// Chakra
import { Container } from '@chakra-ui/react';

// I18next
import { useTranslation } from 'react-i18next';

// Helmet
import Helmet from 'react-helmet';

// Containers
import ActivitiesContainer from 'containers/ActivitiesContainer';

const ActivitiesPage = () => {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>{t('myActivities')}</title>
            </Helmet>
            <Container size='sm'>
                <ActivitiesContainer />
            </Container>
        </>
    );
};

export default ActivitiesPage;