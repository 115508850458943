// Chakra
import { Flex } from '@chakra-ui/react';

// Containers
import HeaderContainer from 'containers/HeaderContainer';

// Components
import CommonLayout from 'layouts/CommonLayout';

const MainLayout: React.FC = ({ children }) => {
    return (
        <CommonLayout>
            <HeaderContainer />
            <Flex
                as='main'
                direction='column'
                flex='1'
                paddingY='50px'
            >
                {children}
            </Flex>
        </CommonLayout>
    );
};

export default MainLayout;