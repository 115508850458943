import {
  useEffect,
  Suspense
} from 'react';

// Redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/Store';
import { RootState } from 'redux/Reducer';
import { getConfiguration } from 'redux/slices/ConfigurationSlice';

// Router
import {
  Redirect,
  Route,
  Switch
} from 'react-router-dom';

// Helmet
import Helmet from 'react-helmet';

// Constants
import { applicationConfiguration } from 'applicationConstants';

// Utilities
import { getLanguageParameterFromPath } from 'utilities/localizationUtilities';

// Layouts
import EmptyLayout from 'layouts/EmptyLayout';
import MainLayout from 'layouts/MainLayout';

// Pages
import AccountPage from 'pages/AccountPage';
import ActivitiesPage from 'pages/ActivitiesPage';
import HomePage from 'pages/HomePage';
import MyAccountPage from 'pages/MyAccountPage';
import NotFoundPage from 'pages/NotFoundPage';
import UsersPage from 'pages/UsersPage';

// Components
import AuthenticatedRoute from 'components/AuthenticatedRoute';
import AdministratorRoute from 'components/AdministratorRoute';
import Loader from 'components/Loader';
import NonAuthenticatedRoute from 'components/NonAuthenticatedRoute';

function App() {
  const dispatch = useAppDispatch();
  const { isFetchingConfiguration } = useSelector((state: RootState) => state.configuration);
  const {
    token,
    isUserSignedIn
  } = useSelector((state: RootState) => state.user);
  const isAuthenticated = token !== null && isUserSignedIn;

  useEffect(() => {
    dispatch(getConfiguration());
  }, []);

  if (isFetchingConfiguration) {
    return (
      <Loader />
    );
  }

  return (
    <Suspense fallback={<Loader />}> {/* React i18next suspense */}
      <Helmet>
        <html lang={getLanguageParameterFromPath() ?? applicationConfiguration.defaultLanguage} />
      </Helmet>
      <Switch>
        <NonAuthenticatedRoute
          redirectToPath='/'
          path='/:language/account'
        >
          <EmptyLayout>
            <AccountPage />
          </EmptyLayout>
        </NonAuthenticatedRoute>
        <AuthenticatedRoute
          redirectToPath={'/account/sign-in'}
          path='/:language'
          exact
        >
          <MainLayout>
            <HomePage />
          </MainLayout>
        </AuthenticatedRoute>
        <AuthenticatedRoute
          redirectToPath={'/account/sign-in'}
          path='/:language/my-activities'
        >
          <MainLayout>
            <ActivitiesPage />
          </MainLayout>
        </AuthenticatedRoute>
        <AuthenticatedRoute
          redirectToPath={'/account/sign-in'}
          path='/:language/my-account'
        >
          <MainLayout>
            <MyAccountPage />
          </MainLayout>
        </AuthenticatedRoute>
        <AdministratorRoute
          redirectToPath={'/account/sign-in'}
          path='/:language/users'
        >
          <MainLayout>
            <UsersPage />
          </MainLayout>
        </AdministratorRoute>
        <Route path='/:language/not-found'>
          {
            isAuthenticated ?
            (
              <MainLayout>
                <NotFoundPage />
              </MainLayout>
            ) :
            (
              <EmptyLayout>
                <NotFoundPage />
              </EmptyLayout>
            )
          }
        </Route>
        <Redirect to='/:language/not-found' />
      </Switch>
    </Suspense>
  );
};

export default App;
