// Chakra
import {
    Box,
    Flex,
    HStack,
    Text
} from '@chakra-ui/react';

// I18next
import { useTranslation } from 'react-i18next';

// Day
import dayjs from 'day';

// Hooks
import useIsComputerScreenSize from 'hooks/useIsComputerScreenSize';

// Components
import LabelledSelector from 'components/LabelledSelector';

interface ICalendarHeaderProps {
    years: number[];
    selectedMonth: number;
    selectedYear: number;
    selectMonthHandler: Function;
    selectYearHandler: Function;
};

const CalendarHeader: React.FC<ICalendarHeaderProps> = ({
    years,
    selectedMonth,
    selectedYear,
    selectMonthHandler,
    selectYearHandler
}) => {
    const { t } = useTranslation();
    const isComputerScreenSize = useIsComputerScreenSize();
    const daysOfWeek = [1, 2, 3, 4, 5, 6, 0];

    return (
        <Flex direction='column'>
            <Flex
                alignSelf={{
                    xl: 'flex-end'
                }}
                direction={{
                    base: 'column-reverse',
                    xl: 'row'
                }}
                mb='1rem'
            >
                <Box
                    mr={{
                        xl: '1rem'
                    }}
                >
                    <LabelledSelector
                        frontLabel={t('month')}
                        {...{
                            value: selectedMonth,
                            onChange: selectMonthHandler
                        }}
                    >
                        {
                            [...Array(12)].map((_, index) => {
                                return (
                                    <option
                                        key={index}
                                        value={index}
                                    >
                                        {dayjs.months()[index]}
                                    </option>
                                );
                            })
                        }
                    </LabelledSelector>
                </Box>
                <Box
                    mb={{
                        base: '1rem',
                        xl: 0
                    }}
                >
                    <LabelledSelector
                        frontLabel={t('year')}
                        {...{
                            value: selectedYear,
                            onChange: selectYearHandler
                        }}
                    >
                        {
                            years.map((year) => {
                                return (
                                    <option
                                        key={year}
                                        value={year}
                                    >
                                        {year}
                                    </option>
                                );
                            })
                        }
                    </LabelledSelector>
                </Box>
            </Flex>
            {isComputerScreenSize && (
                <HStack
                    borderBottom='1px solid #f0f0f0'
                    mb='1rem'
                    pb='1rem'
                    spacing='2px'
                >
                    {
                        daysOfWeek.map((dayOfWeek) => {
                            return (
                                <Text
                                    key={dayOfWeek}
                                    as='span'
                                    flex='1'
                                    fontWeight='700'
                                    p='.5rem 1rem'
                                    textAlign='center'
                                    textTransform='uppercase'
                                >
                                    {dayjs.weekdays()[dayOfWeek]}
                                </Text>
                            );
                        })
                    }
                </HStack>
            )}
        </Flex>
    );
};

export default CalendarHeader;