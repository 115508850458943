// Constants
import { containerMaximumWidths } from 'applicationConstants';

export const Modal = {
    sizes: {
        sm: {
            dialog: {
                maxWidth: containerMaximumWidths.phoneLandscapeMaximumWidth
            }
        },
        md: {
            dialog: {
                maxWidth: containerMaximumWidths.tabletMaximumWidth
            }
        },
        lg: {
            dialog: {
                maxWidth: containerMaximumWidths.tabletLandscapeMaximumWidth
            }
        },
        xl: {
            dialog: {
                maxWidth: containerMaximumWidths.mediumComputerMaximumWidth
            }
        },
        '2xl': {
            dialog: {
                maxWidth: containerMaximumWidths.largeComputerMaximumWidth
            }
        }
    }
};