// Jwt decode
import jwt_decode from 'jwt-decode';

export function isKeyInJwtToken(token: string, key: string) {
    const payload: any = jwt_decode(token);
    if (payload && payload.hasOwnProperty(key)) {
        return true;
    }
    return false;
};

export function getValueByKeyFromJwtToken(token: string, key: string) {
    const payload: any = jwt_decode(token);
    if (!payload || !payload.hasOwnProperty(key)) {
        return undefined;
    }
    if (Array.isArray(payload[key])) {
        return payload[key] as string[];
    } else {
        return [payload[key]] as string[];
    }
};