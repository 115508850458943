// Axios
import axios, {
    AxiosError,
    AxiosResponse
} from 'axios';

// Redux
import store from 'redux/Store';
import { signOut } from 'redux/slices/UserSlice';

// Utilities
import { getLanguageParameterFromPath } from 'utilities/localizationUtilities';

axios.defaults.baseURL = process.env.REACT_APP_API_URL!;

axios.interceptors.request.use(configuration => {
    const state = store.getState();
    const { token } = state.user;
    if (token !== null) {
        configuration.headers.Authorization = `Bearer ${token}`;
    }
    let languageParameter = getLanguageParameterFromPath();
    if (languageParameter !== null) {
        configuration.headers['Accept-Language'] = languageParameter;
    }
    return configuration;
});

axios.interceptors.response.use(async response => {
    return response;
}, (error: AxiosError) => {
    if (error.response === undefined) {
        return Promise.reject(error);
    }
    const {
        status,
        headers
    } = error.response;
    switch (status) {
        case 400:
            break;
        case 401:
            if (
                headers['www-authenticate'] !== undefined &&
                headers['www-authenticate'] !== null &&
                headers['www-authenticate'].startsWith('Bearer error="invalid_token"')
            ) {
                store.dispatch(signOut());
            }
            break;
        case 404:
            break;
        case 500:
            break;
        default:
            break;
    }
    return Promise.reject(error);
});

const responseBody = (response: AxiosResponse) => response.data;

const requests = {
    get: (url: string) => axios.get(url).then(responseBody),
    getWithUrlParameters: (url: string, urlParameters: URLSearchParams) => axios.get(url, { params: urlParameters }).then(responseBody),
    post: (url: string, body: {}) => axios.post(url, body).then(responseBody),
    put: (url: string, body: {}) => axios.put(url, body).then(responseBody),
    delete: (url: string) => axios.delete(url).then(responseBody),
    deleteWithUrlParameters: (url: string, urlParameters: URLSearchParams) => axios.delete(url, { params: urlParameters }).then(responseBody)
};

export default requests;