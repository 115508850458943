// PathToRegexp
import {
    compile,
    pathToRegexp
} from 'path-to-regexp';

export function generateLocalizedPath(language: string, path: string) {
    const route = '/:language/:path*';
    const definePath = compile(route);
    const routeComponents = pathToRegexp(route).exec(path);

    let subPaths = null;
    if (
        routeComponents !== null &&
        routeComponents[2] !== undefined &&
        routeComponents[2] !== null
    ) {
        subPaths = routeComponents[2].split('/');
    }

    return definePath({
        language,
        path: subPaths
    });
};

export function generateLocalizedHref(path: string) {
    const languageParameter = getLanguageParameterFromPath();
    if (path.startsWith('/')) {
        path = path.substring(1, path.length);
    }
    return `/${languageParameter}/${path}`;
};

export function getLanguageParameterFromPath() {
    const subPaths = window.location.pathname.split('/');
    if (
        subPaths &&
        subPaths[1] !== undefined &&
        subPaths[1] !== null
    ) {
        return subPaths[1];
    }
    return null;
};