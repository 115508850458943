// Redux
import { useSelector } from 'react-redux';
import { RootState } from 'redux/Reducer';

// Router
import {
    Redirect,
    Route,
    RouteProps
} from 'react-router-dom';

// Utilities
import { generateLocalizedHref } from 'utilities/localizationUtilities';

interface IAuthenticatedRouteProps extends RouteProps {
    redirectToPath: string;
};

const AuthenticatedRoute: React.FC<IAuthenticatedRouteProps> = ({
    redirectToPath,
    ...routeProps
}) => {
    const {
        token,
        isUserSignedIn
    } = useSelector((state: RootState) => state.user);
    const isAuthenticated = token !== null && isUserSignedIn;

    if (!isAuthenticated) {
        return (
            <Redirect to={generateLocalizedHref(redirectToPath)} />
        );
    }

    return (
        <Route {...routeProps} />
    );
};

export default AuthenticatedRoute;