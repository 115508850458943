// Redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/Store';
import { RootState } from 'redux/Reducer';
import { signOut } from 'redux/slices/UserSlice';

// Utilities
import { isRoleInJwtToken } from 'utilities/appUtilities';

// Layouts
import Header from 'layouts/Header';

const HeaderContainer = () => {
    const dispatch = useAppDispatch();
    const { token } = useSelector((state: RootState) => state.user);
    const isUserAdministrator = token !== null ? isRoleInJwtToken(token, "Administrator") : false;

    const handleSignOut = () => {
        dispatch(signOut());
    };

    return (
        <Header
            isAdministratorMode={isUserAdministrator}
            signOutHandler={handleSignOut}
        />
    );
};

export default HeaderContainer;