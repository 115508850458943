// Chakra
import {
    Button,
    chakra,
    Flex,
    Heading,
    Text
} from '@chakra-ui/react';

// I18next
import { useTranslation } from 'react-i18next';

// Router
import { Link } from 'react-router-dom';

// Utilities
import { generateLocalizedHref } from 'utilities/localizationUtilities';

const NotFound = () => {
    const { t } = useTranslation();

    return (
        <Flex direction='column'>
            <Heading
                as='h1'
                fontSize='2rem'
                fontWeight='700'
                mb='2rem'
                textAlign='center'
            >
                {t('pageNotFound')}
            </Heading>
            <Text
                as='span'
                mb='1rem'
            >
                {t('whyThePageWasNotFound')}
            </Text>
            <Flex
                as='ul'
                direction='column'
                mb='1rem'
                ml='1rem'
            >
                <chakra.li>{t('pageDoNotExist')}</chakra.li>
                <chakra.li>{t('pageHasBeenDeleted')}</chakra.li>
                <chakra.li>{t('pageMightBeTemporarilyUnavailable')}</chakra.li>
            </Flex>
            <Button
                as={Link}
                colorScheme='blue'
                to={generateLocalizedHref('/')}
            >
                {t('backToHomePage')}
            </Button>
        </Flex>
    );
};

export default NotFound;