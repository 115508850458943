// Chakra
import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay
} from '@chakra-ui/react';

interface ITitledModalProps {
    title: string;
    isOpen: boolean;
    size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl'
    closeModalHandler: Function;
};

const TitledModal: React.FC<ITitledModalProps> = ({
    title,
    isOpen,
    size,
    closeModalHandler,
    children
}) => {
    return (
        <Modal
            isCentered
            isOpen={isOpen}
            scrollBehavior='inside'
            size={size}
            onClose={() => closeModalHandler()}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{title}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>{children}</ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default TitledModal;