import {
    useEffect,
    useState
} from 'react';

// Chakra
import {
    useDisclosure,
    useToast
} from '@chakra-ui/react';

// Axios
import { AxiosError } from 'axios';

// I18next
import { useTranslation } from 'react-i18next';

// Hook form
import { NestedValue } from 'react-hook-form';

// Service
import AccountService from 'services/AccountService';

// Form values
import { DefaultUserFormValues } from 'formValues/DefaultUserFormValues';
import { SubmitUserFormValues } from 'formValues/SubmitUserFormValues';

// Utilities
import { isNullOrWhiteSpace } from 'utilities/stringUtilities';

// Models
import { IApplicationUserRequestForUserListEdition } from 'models/ApplicationUser/IApplicationUserRequestForUserListEdition';

// View models
import { IApplicationUserForUserList } from 'viewmodels/ApplicationUser/IApplicationUserForUserList';

// Components
import TitledModal from 'components/modals/TitledModal';
import Users from 'components/Users';
import UserForm from 'components/UserForm';

const UsersContainer = () => {
    const toast = useToast();
    const { t } = useTranslation();
    const {
        isOpen,
        onOpen,
        onClose
    } = useDisclosure();
    const [isFetchingUsers, setIsFetchingUsers] = useState<boolean>(false);
    const [isSubmittingUser, setIsSubmittingUser] = useState<boolean>(false);
    const [users, setUsers] = useState<IApplicationUserForUserList[]>([]);
    const [selectedUser, setSelectedUser] = useState<IApplicationUserForUserList | null>(null);
    const defaultUserWorkDays = process.env.REACT_APP_DEFAULT_USER_WORK_DAYS;

    useEffect(() => {
        setIsFetchingUsers(true);

        AccountService.fetchApplicationUsersForUserList()
            .then((response) => {
                setUsers(response);
            })
            .catch((error: AxiosError) => {
                if (!error.response) {
                    return;
                }

                toast({
                    title: t('anErrorHasOccurred'),
                    description: t('errors.couldNotFetchUsers'),
                    status: 'error',
                    duration: 5000,
                    isClosable: true
                });
            })
            .finally(() => {
                setIsFetchingUsers(false);
            });
    }, []);

    const getDefaultUserFormValues = () => {
        const formValues: DefaultUserFormValues = {
            idMapping: '',
            userName: '',
            email: '',
            password: '',
            confirmPassword: '',
            firstName: '',
            lastName: '',
            workDays: ([] as string[]) as NestedValue<string[]>,
            isAdministrator: false,
            isActive: true
        };

        if (selectedUser !== null) {
            formValues.idMapping = selectedUser.idMapping;
            formValues.userName = selectedUser.userName;
            formValues.email = selectedUser.email;
            formValues.firstName = selectedUser.firstName;
            formValues.lastName = selectedUser.lastName;
            formValues.workDays = selectedUser.workDays.map((workDay) => workDay.toString()) as NestedValue<string[]>;
            formValues.isAdministrator = selectedUser.isAdministrator;
            formValues.isActive = selectedUser.isActive;
        } else {
            if (defaultUserWorkDays !== undefined) {
                formValues.workDays = defaultUserWorkDays.split(',') as NestedValue<string[]>;
            }
        }

        return formValues;
    };

    const handleAddUser = () => {
        setSelectedUser(null);
        onOpen();
    };

    const handleEditUser = (userId: number) => {
        const user = users.find(x => x.id === userId);

        if (user === undefined) return;

        setSelectedUser(user);
        onOpen();
    };

    const handleUserSubmit = (model: SubmitUserFormValues) => {
        const apiModel: IApplicationUserRequestForUserListEdition = {
            idMapping: !isNullOrWhiteSpace(model.idMapping) ? model.idMapping : null,
            userName: !isNullOrWhiteSpace(model.userName) ? model.userName : null,
            email: !isNullOrWhiteSpace(model.email) ? model.email : null,
            password: !isNullOrWhiteSpace(model.password) ? model.password : null,
            firstName: model.firstName,
            lastName: model.lastName,
            workDays: model.workDays.map((workDay) => Number(workDay)),
            isAdministrator: model.isAdministrator,
            isActive: model.isActive
        };

        setIsSubmittingUser(true);

        if (selectedUser !== null) {
            AccountService.updateUserForUserList(selectedUser.id, apiModel)
                .then((response) => {
                    setUsers(previousState => {
                        const shallowCopy = [...previousState];
                        const userIndex = shallowCopy.findIndex(x => x.id === selectedUser.id);
                        if (userIndex === -1) {
                            return shallowCopy;
                        }
                        return [
                            ...shallowCopy.slice(0, userIndex),
                            response,
                            ...shallowCopy.slice(userIndex + 1)
                        ];
                    });
                    onClose();
                })
                .catch((error: AxiosError) => {
                    if (!error.response) {
                        return;
                    }

                    toast({
                        title: t('anErrorHasOccurred'),
                        description: t('errors.couldNotUpdateUser'),
                        status: 'error',
                        duration: 5000,
                        isClosable: true
                    });
                })
                .finally(() => {
                    setIsSubmittingUser(false);
                });
        } else {
            AccountService.createUserForUserList(apiModel)
                .then((response) => {
                    setUsers(previousState => {
                        return [
                            ...previousState,
                            response
                        ];
                    });
                    onClose();
                })
                .catch((error: AxiosError) => {
                    if (!error.response) {
                        return;
                    }
                    
                    toast({
                        title: t('anErrorHasOccurred'),
                        description: t('errors.couldNotCreateUser'),
                        status: 'error',
                        duration: 5000,
                        isClosable: true
                    });
                })
                .finally(() => {
                    setIsSubmittingUser(false);
                });
        }
    };

    return (
        <>
            <Users
                users={users}
                isFetchingUsers={isFetchingUsers}
                addUserButtonClickHandler={handleAddUser}
                editUserHandler={handleEditUser}
            />
            <TitledModal
                title={selectedUser !== null ? t('editUser') : t('addUser')}
                isOpen={isOpen}
                size='lg'
                closeModalHandler={onClose}
            >
                <UserForm
                    formValues={getDefaultUserFormValues()}
                    isCreationMode={selectedUser === null}
                    isSubmitting={isSubmittingUser}
                    submitHandler={handleUserSubmit}
                />
            </TitledModal>
        </>
    );
};

export default UsersContainer;