// Chakra
import {
    Button,
    Flex,
    HStack,
    Image,
    Link as ChakraLink,
    Menu,
    MenuButton,
    MenuItem,
    MenuList
} from '@chakra-ui/react';

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCalendarAlt,
    faIdCard,
    faSignOut,
    faUser,
    faUsers,
} from '@fortawesome/pro-light-svg-icons';

// I18next
import { useTranslation } from 'react-i18next';

// Router
import {
    Link,
    NavLink
} from 'react-router-dom';

// Utilities
import { generateLocalizedHref } from 'utilities/localizationUtilities';

// Components
import ResponsiveContainer from 'components/ResponsiveContainer';

interface IAppBarProps {
    isAdministratorMode: boolean;
    signOutHandler: Function;
};

const AppBar: React.FC<IAppBarProps> = ({
    isAdministratorMode,
    signOutHandler
}) => {
    const { t } = useTranslation();

    return (
        <Flex
            bgColor='#fff'
            boxShadow='0px 4px 4px 0px rgba(0, 0, 0, .2)'
            py='1rem'
        >
            <ResponsiveContainer>
                <Flex
                    alignItems='center'
                    justifyContent='space-between'
                >
                    <HStack spacing='1rem'>
                        <ChakraLink
                            as={Link}
                            height='50px'
                            maxHeight='50px'
                            overflow='hidden'
                            to={generateLocalizedHref('/')}
                        >
                            <Image
                                alt='Logo'
                                margin='0 auto'
                                maxHeight='100%'
                                maxWidth='100%'
                                objectFit='contain'
                                src='/images/logo.png'
                            />
                        </ChakraLink>
                        <Button
                            as={NavLink}
                            colorScheme='blue'
                            leftIcon={<FontAwesomeIcon icon={faCalendarAlt} />}
                            to={generateLocalizedHref('/')}
                            variant='ghost'
                        >
                            {t('activitiesSummary')}
                        </Button>
                        {isAdministratorMode && (
                            <Button
                                as={NavLink}
                                colorScheme='blue'
                                leftIcon={<FontAwesomeIcon icon={faUsers} />}
                                to={generateLocalizedHref('/users')}
                                variant='ghost'
                            >
                                {t('userManagement')}
                            </Button>
                        )}
                        <Menu>
                            <MenuButton
                                as={Button}
                                colorScheme='blue'
                                leftIcon={<FontAwesomeIcon icon={faUser} />}
                                variant='ghost'
                            >
                                {t('myAccount')}
                            </MenuButton>
                            <MenuList>
                                <MenuItem
                                    as={NavLink}
                                    icon={<FontAwesomeIcon icon={faIdCard} />}
                                    to={generateLocalizedHref('/my-account/user-profile')}
                                >
                                    {t('myUserProfile')}
                                </MenuItem>
                                <MenuItem
                                    icon={<FontAwesomeIcon icon={faSignOut} />}
                                    onClick={() => signOutHandler()}
                                >
                                    {t('signOut')}
                                </MenuItem>
                            </MenuList>
                        </Menu>
                    </HStack>
                </Flex>
            </ResponsiveContainer>
        </Flex>
    );
};

export default AppBar;