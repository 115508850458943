// Chakra
import {
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input
} from '@chakra-ui/react';

// Font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignIn } from '@fortawesome/pro-light-svg-icons';

// I18next
import { useTranslation } from 'react-i18next';

// Hook form
import { useForm } from 'react-hook-form';

// Models
import { SignInFormValues } from 'formValues/SignInFormValues';

interface ISignInFormProps {
    isSubmitting: boolean;
    submitHandler: Function;
};

const SignInForm: React.FC<ISignInFormProps> = ({
    isSubmitting,
    submitHandler
}) => {
    const { t } = useTranslation();
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<SignInFormValues>();

    const handleSubmitForm = (model: SignInFormValues) => {
        if (isSubmitting) return;
        submitHandler(model);
    };
    
    return (
        <Flex
            as='form'
            direction='column'
            onSubmit={handleSubmit(handleSubmitForm)}
        >
            <FormControl
                id='login'
                isInvalid={errors.login !== undefined}
                isRequired
                mb='1rem'
            >
                <FormLabel>{t('login')}</FormLabel>
                <Input
                    variant='filled'
                    {...register('login', {
                        required: t('loginRequired').toString()
                    })}
                />
                {errors.login !== undefined && (
                    <FormErrorMessage as='span'>{errors.login.message}</FormErrorMessage>
                )}
            </FormControl>
            <FormControl
                id='password'
                isInvalid={errors.password !== undefined}
                isRequired
                mb='1rem'
            >
                <FormLabel>{t('password')}</FormLabel>
                <Input
                    type='password'
                    variant='filled'
                    {...register('password', {
                        required: t('passwordRequired').toString()
                    })}
                />
                {errors.password !== undefined && (
                    <FormErrorMessage as='span'>{errors.password.message}</FormErrorMessage>
                )}
            </FormControl>
            <Button
                colorScheme='blue'
                isLoading={isSubmitting}
                leftIcon={<FontAwesomeIcon icon={faSignIn} />}
                type='submit'
            >
                {t('signIn')}
            </Button>
        </Flex>
    );
};

export default SignInForm;