// Chakra
import {
    Flex,
    Text
} from '@chakra-ui/react';

// I18next
import { useTranslation } from 'react-i18next';

const Error = () => {
    const { t } = useTranslation();

    return (
        <Flex direction='column'>
            <Text
                as='span'
                fontSize='2rem'
                fontWeight='700'
                mb='2rem'
                textAlign='center'
            >
                {t('anErrorHasOccurred')}
            </Text>
        </Flex>
    );
};

export default Error;