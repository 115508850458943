// Chakra
import { Flex } from '@chakra-ui/react';

// Components
import CommonLayout from 'layouts/CommonLayout';

const EmptyLayout: React.FC = ({ children }) => {
    return (
        <CommonLayout>
            <Flex
                as='main'
                direction='column'
                flex='1'
                paddingY='50px'
            >
                {children}
            </Flex>
        </CommonLayout>
    );
};

export default EmptyLayout;