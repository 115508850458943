export function isNullOrWhiteSpace(value: string | null | undefined) {
    return (
        value === undefined ||
        value === null ||
        value.trim() === ''
    );
};

export function equalsWithIgnoreCase(value1: string, value2: string) {
    return value1.localeCompare(value2, undefined, { sensitivity: 'base' }) === 0;
};