// Chakra
import { Container } from '@chakra-ui/react';

// Constants
import { containerMaximumWidths } from 'applicationConstants';

const ResponsiveContainer: React.FC = ({ children }) => {
    return (
        <Container
            maxWidth={{
                sm: containerMaximumWidths.phoneLandscapeMaximumWidth,
                md: containerMaximumWidths.tabletMaximumWidth,
                lg: containerMaximumWidths.tabletLandscapeMaximumWidth,
                xl: containerMaximumWidths.mediumComputerMaximumWidth,
                '2xl': containerMaximumWidths.largeComputerMaximumWidth
            }}
        >
            {children}
        </Container>
    );
};

export default ResponsiveContainer;