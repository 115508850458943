// Chakra
import {
    Flex,
    IconButton,
    Text,
    Tooltip
} from '@chakra-ui/react';

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/pro-light-svg-icons';

// I18next
import {
    Trans,
    useTranslation
} from 'react-i18next';

// View models
import { IApplicationUserForUserList } from 'viewmodels/ApplicationUser/IApplicationUserForUserList';

interface IUserListItemProps {
    user: IApplicationUserForUserList;
    editUserButtonClickHandler: Function;
};

const UserListItem: React.FC<IUserListItemProps> = ({
    user,
    editUserButtonClickHandler
}) => {
    const { t } = useTranslation();

    return (
        <Flex
            as='li'
            bgColor='#fff'
            borderRadius='.5rem'
            boxShadow='0px 4px 4px 0px rgba(0, 0, 0, .2)'
            direction='column'
            padding='1rem'
        >
            <Flex
                justifyContent='space-between'
                mb='1rem'
            >
                <Text
                    as='span'
                    fontWeight='700'
                    mr='1rem'
                >
                    {user.firstName} {user.lastName}
                </Text>
                <Tooltip label={t('editUser')}>
                    <IconButton
                        aria-label={t('editUser')}
                        colorScheme='blue'
                        icon={<FontAwesomeIcon icon={faPen} />}
                        variant='ghost'
                        onClick={() => editUserButtonClickHandler(user.id)}
                    />
                </Tooltip>
            </Flex>
            <Flex justifyContent='space-between'>
                <Text
                    as='span'
                    mr='1rem'
                >
                    <Trans
                        i18nKey='labelComponentColonWithValueComponent'
                        values={{
                            label: t('status'),
                            value: !user.isActive ? t('inactive') : t('active')
                        }}
                        components={{
                            label:
                                <Text
                                    as='span'
                                    fontWeight='700'
                                />,
                            value:
                                <Text
                                    as='span'
                                    color={!user.isActive ? 'red.500' : 'green.500'}
                                    fontWeight='700'
                                />
                        }}
                    />
                </Text>
                <Text as='span'>
                    <Trans
                        i18nKey='labelComponentColonWithValue'
                        values={{
                            label: t('role'),
                            value: !user.isAdministrator ? t('user') : t('administrator')
                        }}
                        components={{
                            label:
                                <Text
                                    as='span'
                                    fontWeight='700'
                                />
                        }}
                    />
                </Text>
            </Flex>
        </Flex>
    );
};

export default UserListItem;