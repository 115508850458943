// Services
import requests from './ApiService';

// Models
import { IApplicationUserRequestForSignIn } from 'models/ApplicationUser/IApplicationUserRequestForSignIn';
import { IApplicationUserRequestForChangeEmail } from 'models/ApplicationUser/IApplicationUserRequestForChangeEmail';
import { IApplicationUserRequestForChangePassword } from 'models/ApplicationUser/IApplicationUserRequestForChangePassword';
import { IApplicationUserRequestForChangeUserDetails } from 'models/ApplicationUser/IApplicationUserRequestForChangeUserDetails';
import { IApplicationUserRequestForChangeUserName } from 'models/ApplicationUser/IApplicationUserRequestForChangeUserName';
import { IApplicationUserResponseForSignIn } from 'models/ApplicationUser/IApplicationUserResponseForSignIn';
import { IApplicationUserRequestForUserListEdition } from 'models/ApplicationUser/IApplicationUserRequestForUserListEdition';

// View models
import { IApplicationUserForUserList } from 'viewmodels/ApplicationUser/IApplicationUserForUserList';
import { IApplicationUserForUserProfile } from 'viewmodels/ApplicationUser/IApplicationUserForUserProfile';

const routeName = 'accounts';

const AccountService = {
    fetchMyApplicationUserForUserProfile: (): Promise<IApplicationUserForUserProfile> => requests.get(`${routeName}/userprofile`),
    fetchApplicationUsersForUserList: (): Promise<IApplicationUserForUserList[]> => requests.get(`${routeName}/userlist`),
    signIn: (model: IApplicationUserRequestForSignIn): Promise<IApplicationUserResponseForSignIn> =>
        requests.post(`${routeName}/signin`, model),
    createUserForUserList: (model: IApplicationUserRequestForUserListEdition): Promise<IApplicationUserForUserList> =>
        requests.post(`${routeName}/userlistedition`, model),
    changeUserNameOfCurrentUser: (model: IApplicationUserRequestForChangeUserName): Promise<void> =>
        requests.put(`${routeName}/changeusername`, model),
    changeEmailOfCurrentUser: (model: IApplicationUserRequestForChangeEmail): Promise<void> =>
        requests.put(`${routeName}/changeemail`, model),
    changePasswordOfCurrentUser: (model: IApplicationUserRequestForChangePassword): Promise<void> =>
        requests.put(`${routeName}/changepassword`, model),
    changeUserDetailsOfCurrentUser: (model: IApplicationUserRequestForChangeUserDetails): Promise<void> =>
        requests.put(`${routeName}/changeuserdetails`, model),
    updateUserForUserList: (id: number, model: IApplicationUserRequestForUserListEdition): Promise<IApplicationUserForUserList> =>
        requests.put(`${routeName}/${id}/userlistedition`, model)
};

export default AccountService;