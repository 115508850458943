// I18next
import { useTranslation } from 'react-i18next';

// Helmet
import Helmet from 'react-helmet';

// Containers
import CalendarContainer from 'containers/CalendarContainer';

// Components
import ResponsiveContainer from 'components/ResponsiveContainer';

const HomePage = () => {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>{t('activitiesSummary')}</title>
            </Helmet>
            <ResponsiveContainer>
                <CalendarContainer />
            </ResponsiveContainer>
        </>
    );
};

export default HomePage;