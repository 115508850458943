// Chakra
import { extendTheme } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';
import { Container } from 'styles/components/Container';
import { Drawer } from 'styles/components/Drawer';
import { Modal } from 'styles/components/Modal';

// Constants
import { screenBreakpoints } from 'applicationConstants';

const theme = extendTheme({
    styles: {
        global: {
            body: {
                bgColor: '#f8f8f8',
                color: '#303030',
                fontFamily: 'Lato, Arial, sans-serif',
                lineHeight: '1.5'
            }
        }
    },
    colors: {
        /*customScheme: {
            50: '#',
            100: '#',
            200: '#',
            300: '#',
            400: '#',
            500: '#',
            600: '#',
            700: '#',
            800: '#',
            900: '#',
        }*/
    },
    components: {
        Container,
        Drawer,
        Modal
    }
});

createBreakpoints({
    sm: screenBreakpoints.phoneLandscapeBreakpoint + 'px',
    md: screenBreakpoints.tabletBreakpoint + 'px',
    lg: screenBreakpoints.tabletLandscapeBreakpoint + 'px',
    xl: screenBreakpoints.mediumComputerBreakpoint + 'px',
    '2xl': screenBreakpoints.largeComputerBreakpoint + 'px'
});

export default theme;