// Chakra
import {
    Link,
    Text
} from '@chakra-ui/react';

// I18next
import { useTranslation } from 'react-i18next';

interface ICopyrightProps {
    owner: string;
    ownerWebsiteUrl?: string;
};

const Copyright: React.FC<ICopyrightProps> = ({
    owner,
    ownerWebsiteUrl
}) => {
    const { t } = useTranslation();
    const currentYear = new Date().getFullYear();

    const getOwnerContent = () => {
        if (ownerWebsiteUrl !== undefined && ownerWebsiteUrl !== null) {
            return (
                <Link href={ownerWebsiteUrl}>{owner}</Link>
            );
        };
    
        return owner;
    };

    return (
        <Text
            as='small'
            bgColor='#303030'
            color='#fff'
            display='block'
            fontSize='.8rem'
            p='1rem'
            textAlign='center'
        >
            Copyright &copy; {currentYear} {getOwnerContent()}. {t('allRightsReserved')}.
        </Text>
    );
};

export default Copyright;