// Chakra
import {
    BackgroundProps,
    Box,
    Center,
    ColorProps,
    EffectProps,
    Flex,
    GridProps,
    InteractivityProps,
    Text
} from '@chakra-ui/react';
import { CSSObject } from '@chakra-ui/styled-system';

// I18next
import {
    Trans,
    useTranslation
} from 'react-i18next';

// Day
import dayjs from 'day';

// Utilities
import { isSameOrLaterDate } from 'utilities/dateUtilities';
import { formatTimeFromIso8601 } from 'utilities/timeUtilities';

// Hooks
import useIsComputerScreenSize from 'hooks/useIsComputerScreenSize';

interface ICalendarItemProps {
    year: number;
    month: number;
    day: number;
    iso8601TotalWorkTime?: string;
    iso8601ValidWorkTime?: string;
    isDayDisabled?: boolean;
    firstDayGridProps?: GridProps;
    dayButtonClickHandler: Function;
};

const CalendarItem: React.FC<ICalendarItemProps> = ({
    year,
    month,
    day,
    iso8601TotalWorkTime,
    iso8601ValidWorkTime,
    isDayDisabled = false,
    firstDayGridProps,
    dayButtonClickHandler
}) => {
    const { t } = useTranslation();
    const isSameOrLater = isSameOrLaterDate(new Date(year, month, day), new Date());
    const isComputerScreenSize = useIsComputerScreenSize();

    const isWorkTimeValid = () => {
        if (iso8601ValidWorkTime === undefined) {
            return true;
        }
        if (iso8601TotalWorkTime === undefined) {
            return false;
        }
        const totalWorkTimeDuration = dayjs.duration(dayjs.duration(iso8601TotalWorkTime).asMilliseconds());
        const validWorkTimeDuration = dayjs.duration(dayjs.duration(iso8601ValidWorkTime).asMilliseconds());
        const duration = dayjs.duration(dayjs(totalWorkTimeDuration.asMilliseconds()).diff(validWorkTimeDuration.asMilliseconds()));
        if (duration.asMilliseconds() < 0) {
            return false;
        }
        return true;
    };

    const getHoverStyle = () => {
        if (!isDayDisabled) {
            const hoverProps: CSSObject = {};
            if (iso8601ValidWorkTime !== undefined && !isSameOrLater) {
                hoverProps.bgColor = !isWorkTimeValid() ? 'red.600' : 'green.600'
            } else {
                hoverProps.bgColor = 'blue.500';
            }
            return {
                ...hoverProps
            };
        }
    };

    const getGroupHoverStyle = () => {
        if (isSameOrLater && !isDayDisabled) {
            const groupHoverProps: CSSObject = {
                color: '#fff'
            };
            return {
                ...groupHoverProps
            };
        }
    };

    const getValidStatusStyle = () => {
        if (
            iso8601ValidWorkTime !== undefined &&
            !isSameOrLater &&
            !isDayDisabled
        ) {
            const backgroundProps: BackgroundProps = {
                bgColor: !isWorkTimeValid() ? 'red.500' : 'green.500'
            };
            return {
                ...backgroundProps
            };
        }
    }

    const getDisabledStyle = () => {
        if (isDayDisabled) {
            const backgroundProps: BackgroundProps = {
                bgColor: '#f0f0f0'
            };
            const effectProps: EffectProps = {
                opacity: '.5'
            };
            const interactivityProps: InteractivityProps = {
                cursor: 'not-allowed'
            };
            return {
                ...backgroundProps,
                ...effectProps,
                ...interactivityProps
            }
        }
    };

    const getDayNameStyle = () => {
        if (!isSameOrLater && !isDayDisabled) {
            const colorProps: ColorProps = {
                color: '#fff'
            };
            return {
                ...colorProps
            };
        }
    };

    const getDayNumberStyle = () => {
        if (!isDayDisabled) {
            const colorProps: ColorProps = {
                color: !isSameOrLater ? '#fff' : 'blue.500'
            };
            return {
                ...colorProps
            };
        }
    };

    const getWorkTimeStyle = () => {
        if (!isSameOrLater && !isDayDisabled) {
            const colorProps: ColorProps = {
                color: '#fff'
            };
            return {
                ...colorProps
            };
        }
    };

    return (
        <Center
            as='button'
            bgColor='#fff'
            disabled={isDayDisabled}
            padding='1rem'
            role='group'
            transition='.5s ease'
            _first={{
                ...firstDayGridProps
            }}
            {...getValidStatusStyle()}
            {...getDisabledStyle()}
            _hover={getHoverStyle()}
            onClick={() => dayButtonClickHandler(day)}
        >
            <Flex direction='column'>
                <Box mb='.5rem'>
                    {
                        !isComputerScreenSize ?
                        (
                            <Text
                                as='span'
                                fontSize='1.5rem'
                                {...getDayNameStyle()}
                                _groupHover={getGroupHoverStyle()}
                            >
                                {`${dayjs(new Date(year, month, day)).format('dddd')}, `}
                                <Text
                                    as='span'
                                    fontWeight='700'
                                    {...getDayNumberStyle()}
                                    _groupHover={getGroupHoverStyle()}
                                >
                                    {day}
                                </Text>
                            </Text>
                        ) :
                        (
                            <Text
                                as='span'
                                fontSize='1.5rem'
                                fontWeight='700'
                                {...getDayNumberStyle()}
                                _groupHover={getGroupHoverStyle()}
                            >
                                {day}
                            </Text>
                        )
                    }
                </Box>
                <Text
                    as='span'
                    textAlign='center'
                    {...getWorkTimeStyle()}
                    _groupHover={getGroupHoverStyle()}
                >
                    {
                        iso8601TotalWorkTime !== undefined ?
                        (
                            <Trans
                                i18nKey='labelComponentColonWithValue'
                                values={{
                                    label: t('workTime'),
                                    value: formatTimeFromIso8601(iso8601TotalWorkTime)
                                }}
                                components={{
                                    label:
                                        <Text
                                            as='span'
                                            fontWeight='700'
                                            {...getWorkTimeStyle()}
                                        />
                                }}
                            />
                        ) :
                        (
                            t('noWorkTime')
                        )
                    }
                </Text>
            </Flex>
        </Center>
    );
};

export default CalendarItem;