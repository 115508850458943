// Chakra
import {
    Flex,
    GridProps,
    SimpleGrid
} from '@chakra-ui/react';

// Utilities
import { anyElement } from 'utilities/arrayUtilities';
import { daysInMonth } from 'utilities/dateUtilities';

// Models
import { IActivityResponseForTotalWorkTimePerDay } from 'models/Activity/IActivityResponseForTotalWorkTimePerDay';

// Components
import CalendarHeader from './CalendarHeader';
import CalendarItem from './CalendarItem';

interface ICalendarProps {
    years: number[];
    totalWorkTimesPerDay: IActivityResponseForTotalWorkTimePerDay[];
    iso8601ValidWorkTime?: string;
    disabledDaysOfWeek?: number[];
    selectedYear: number;
    selectedMonth: number;
    selectMonthHandler: Function;
    selectYearHandler: Function;
    selectDayHandler: Function;
};

const Calendar: React.FC<ICalendarProps> = ({
    years,
    totalWorkTimesPerDay,
    iso8601ValidWorkTime,
    disabledDaysOfWeek,
    selectedMonth,
    selectedYear,
    selectMonthHandler,
    selectYearHandler,
    selectDayHandler
}) => {
    const getFirstDayGridProps = () => {
        const weekDayOfFirstDayOfTheMonth = new Date(selectedYear, selectedMonth, 1).getDay();
        const gridProps: GridProps = {
            gridColumnStart: {
                xl: weekDayOfFirstDayOfTheMonth === 0 ? 7 : weekDayOfFirstDayOfTheMonth
            }
        };
        return gridProps;
    };

    const getIso8601TotalWorkTimeForDay = (day: number) => {
        if (!anyElement(totalWorkTimesPerDay)) {
            return undefined;
        }
        const totalWorkTimePerDay = totalWorkTimesPerDay.find(x => x.day === day);
        if (totalWorkTimePerDay === undefined) {
            return undefined;
        }
        return totalWorkTimePerDay.iso8601TotalWorkTime;
    };

    const isDayDisabled = (day: number) => {
        if (disabledDaysOfWeek === undefined || !anyElement(disabledDaysOfWeek)) {
            return false;
        }
        if (disabledDaysOfWeek.includes(new Date(selectedYear, selectedMonth, day).getDay())) {
            return true;
        }
        return false;
    };

    return (
        <Flex direction='column'>
            {
                <CalendarHeader
                    years={years}
                    selectedMonth={selectedMonth}
                    selectedYear={selectedYear}
                    selectMonthHandler={selectMonthHandler}
                    selectYearHandler={selectYearHandler}
                />
            }
            <SimpleGrid
                columns={{
                    base: 1,
                    xl: 7
                }}
                gap='2px'
                gridAutoRows='1fr'
                gridTemplateRows='minmax(100px, 1fr)'
            >
                {
                    [...Array(daysInMonth(selectedYear, selectedMonth))].map((_, index) => {
                        return (
                            <CalendarItem
                                key={index + 1}
                                year={selectedYear}
                                month={selectedMonth}
                                day={index + 1}
                                iso8601TotalWorkTime={getIso8601TotalWorkTimeForDay(index + 1)}
                                iso8601ValidWorkTime={iso8601ValidWorkTime}
                                isDayDisabled={isDayDisabled(index + 1)}
                                firstDayGridProps={getFirstDayGridProps()}
                                dayButtonClickHandler={selectDayHandler}
                            />
                        );
                    })
                }
            </SimpleGrid>
        </Flex>
    );
};

export default Calendar;