// Constants
import { containerMaximumWidths } from 'applicationConstants';

export const Container = {
    sizes: {
        sm: {
            maxWidth: containerMaximumWidths.phoneLandscapeMaximumWidth
        },
        md: {
            maxWidth: containerMaximumWidths.tabletMaximumWidth
        },
        lg: {
            maxWidth: containerMaximumWidths.tabletLandscapeMaximumWidth
        },
        xl: {
            maxWidth: containerMaximumWidths.mediumComputerMaximumWidth
        },
        '2xl': {
            maxWidth: containerMaximumWidths.largeComputerMaximumWidth
        }
    }
};