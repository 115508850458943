// Utilities
import { getValueByKeyFromJwtToken } from './jwtUtilities';

export function getRolesFromJwtToken(token: string) {
    const roles = getValueByKeyFromJwtToken(token, 'role'); // 'role' is a .NET standard key
    if (roles === undefined) return undefined;
    return roles;
};

export function isRoleInJwtToken(token: string, role: string) {
    const roles = getRolesFromJwtToken(token);
    if (roles === undefined) return false;
    if (roles.findIndex(x => x === role) === -1) {
        return false;
    }
    return true;
};

export function getWorkDaysFromJwtToken(token: string) {
    const workDays = getValueByKeyFromJwtToken(token, 'WorkDays');
    if (workDays === undefined) return undefined;
    return [...workDays.map((workDay) => {
        return Number(workDay);
    })];
};