import {
    Button,
    Flex,
    IconButton,
    Text,
    Tooltip
} from '@chakra-ui/react';

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/pro-light-svg-icons';

// I18next
import { useTranslation } from 'react-i18next';

// View models
import { IApplicationUserForUserProfile } from 'viewmodels/ApplicationUser/IApplicationUserForUserProfile';

interface IUserDetailsProps {
    user: IApplicationUserForUserProfile;
    editUserNameButtonClickHandler: Function;
    editEmailButtonClickHandler: Function;
    editPasswordButtonClickHandler: Function;
    editUserDetailsButtonClickHandler: Function;
};

const UserDetails: React.FC<IUserDetailsProps> = ({
    user,
    editUserNameButtonClickHandler,
    editEmailButtonClickHandler,
    editPasswordButtonClickHandler,
    editUserDetailsButtonClickHandler
}) => {
    const { t } = useTranslation();

    return (
        <Flex
            direction={{
                base: 'column',
                lg: 'row'
            }}
        >
            <Flex
                direction='column'
                flex='1'
                mb={{
                    base: '1rem',
                    lg: 0
                }}
                mr={{
                    base: 0,
                    lg: '2rem'
                }}
            >
                <Flex
                    justifyContent='space-between'
                    mb='1rem'
                >
                    <Flex
                        direction='column'
                        mr='1rem'
                    >
                        <Text
                            as='span'
                            fontWeight='700'
                        >
                            {t('userName')}
                        </Text>
                        <Text as='span'>{user.userName}</Text>
                    </Flex>
                    <Tooltip label={t('editMyUserName')}>
                        <IconButton
                            aria-label={t('editMyUserName')}
                            colorScheme='blue'
                            icon={<FontAwesomeIcon icon={faPen} />}
                            variant='ghost'
                            onClick={() => editUserNameButtonClickHandler()}
                        />
                    </Tooltip>
                </Flex>
                <Flex
                    justifyContent='space-between'
                    mb='1rem'
                >
                    <Flex
                        direction='column'
                        mr='1rem'
                    >
                        <Text
                            as='span'
                            fontWeight='700'
                        >
                            {t('email')}
                        </Text>
                        <Text as='span'>{user.email}</Text>
                    </Flex>
                    <Tooltip label={t('editMyEmail')}>
                        <IconButton
                            aria-label={t('editMyEmail')}
                            colorScheme='blue'
                            icon={<FontAwesomeIcon icon={faPen} />}
                            variant='ghost'
                            onClick={() => editEmailButtonClickHandler()}
                        />
                    </Tooltip>
                </Flex>
                <Flex justifyContent='space-between'>
                    <Flex
                        direction='column'
                        mr='1rem'
                    >
                        <Text
                            as='span'
                            fontWeight='700'
                        >
                            {t('password')}
                        </Text>
                        <Text as='span'>****************</Text>
                    </Flex>
                    <Tooltip label={t('editMyPassword')}>
                        <IconButton
                            aria-label={t('editMyPassword')}
                            colorScheme='blue'
                            icon={<FontAwesomeIcon icon={faPen} />}
                            variant='ghost'
                            onClick={() => editPasswordButtonClickHandler()}
                        />
                    </Tooltip>
                </Flex>
            </Flex>
            <Flex
                direction='column'
                flex='1'
            >
                <Flex
                    direction={{
                        base: 'column',
                        lg: 'row'
                    }}
                    mb='1rem'
                >
                    <Flex
                        direction='column'
                        mr='1rem'
                    >
                        <Text
                            as='span'
                            fontWeight='700'
                        >
                            {t('firstName')}
                        </Text>
                        <Text as='span'>{user.firstName}</Text>
                    </Flex>
                    <Flex direction='column'>
                        <Text
                            as='span'
                            fontWeight='700'
                        >
                            {t('lastName')}
                        </Text>
                        <Text as='span'>{user.lastName}</Text>
                    </Flex>
                </Flex>
                <Button
                    colorScheme='blue'
                    variant='outline'
                    onClick={() => editUserDetailsButtonClickHandler()}
                >
                    {t('editMyDetails')}
                </Button>
            </Flex>
        </Flex>
    );
};

export default UserDetails;