// Chakra
import { useToast } from '@chakra-ui/react';

// Redux
import { useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from 'redux/Store';
import { RootState } from 'redux/Reducer';
import { signIn } from 'redux/slices/UserSlice';

// I18next
import { useTranslation } from 'react-i18next';

// Form values
import { SignInFormValues } from 'formValues/SignInFormValues';

// Models
import { IApplicationUserRequestForSignIn } from 'models/ApplicationUser/IApplicationUserRequestForSignIn';

// Components
import SignIn from 'components/SignIn/SignIn';

const SignInContainer = () => {
    const dispatch = useAppDispatch();
    const toast = useToast();
    const { t } = useTranslation();
    const { isUserSigningIn } = useSelector((state: RootState) => state.user);

    const handleSignIn = (model: SignInFormValues) => {
        dispatch(signIn(model as IApplicationUserRequestForSignIn))
            .then(unwrapResult)
            .catch((rejectedPayload) => {
                if (!rejectedPayload.data || !rejectedPayload.data.code) {
                    return;
                }

                let description = t('anErrorHasOccurred');

                switch (rejectedPayload.data.code) {
                    case 'INVALID_CREDENTIALS':
                        description = t('invalidCredentials');
                        break;
                    case 'ACCOUNT_LOCKED_OUT':
                        description = t('accountLockedOut');
                        break;
                }
                
                toast({
                    title: t('anErrorHasOccurred'),
                    description: description,
                    status: 'error',
                    duration: 5000,
                    isClosable: true
                });
            });
    };

    return (
        <SignIn
            isSigningIn={isUserSigningIn}
            signInHandler={handleSignIn}
        />
    );
};

export default SignInContainer;