// Chakra
import {
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input
} from '@chakra-ui/react';

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-light-svg-icons';

// I18next
import { useTranslation } from 'react-i18next';

// Hook form
import { useForm } from 'react-hook-form';

// Form values
import { ChangePasswordFormValues } from 'formValues/ChangePasswordFormValues';

interface IChangePasswordFormProps {
    formValues?: ChangePasswordFormValues;
    isSubmitting: boolean;
    submitHandler: Function;
};

const ChangePasswordForm: React.FC<IChangePasswordFormProps> = ({
    formValues,
    isSubmitting,
    submitHandler
}) => {
    const { t } = useTranslation();
    const {
        register,
        getValues,
        handleSubmit,
        formState: {
            isDirty,
            errors
        }
    } = useForm<ChangePasswordFormValues>({
        defaultValues: {
            currentPassword: formValues?.currentPassword ?? '',
            newPassword: formValues?.newPassword ?? '',
            confirmNewPassword: formValues?.confirmNewPassword ?? ''
        }
    });

    const handleSubmitForm = (model: ChangePasswordFormValues) => {
        if (!isDirty || isSubmitting) return;
        submitHandler(model);
    };

    return (
        <Flex
            as='form'
            direction='column'
            onSubmit={handleSubmit(handleSubmitForm)}
        >
            <FormControl
                id='currentPassword'
                isInvalid={errors.currentPassword !== undefined}
                isRequired
                mb='1rem'
            >
                <FormLabel>{t('currentPassword')}</FormLabel>
                <Input
                    type='password'
                    variant='filled'
                    {...register('currentPassword', {
                        required: t('passwordRequired').toString()
                    })}
                />
                {errors.currentPassword !== undefined && (
                    <FormErrorMessage as='span'>{errors.currentPassword.message}</FormErrorMessage>
                )}
            </FormControl>
            <FormControl
                id='newPassword'
                isInvalid={errors.newPassword !== undefined}
                isRequired
                mb='1rem'
            >
                <FormLabel>{t('newPassword')}</FormLabel>
                <Input
                    type='password'
                    variant='filled'
                    {...register('newPassword', {
                        required: t('passwordRequired').toString(),
                        minLength: { value: 8, message: t('passwordMinimumLength', { length: 8 }).toString() }
                    })}
                />
                {errors.newPassword !== undefined && (
                    <FormErrorMessage as='span'>{errors.newPassword.message}</FormErrorMessage>
                )}
            </FormControl>
            <FormControl
                id='confirmNewPassword'
                isInvalid={errors.confirmNewPassword !== undefined}
                isRequired
                mb='1rem'
            >
                <FormLabel>{t('confirmPassword')}</FormLabel>
                <Input
                    type='password'
                    variant='filled'
                    {...register('confirmNewPassword', {
                        required: t('passwordRequired').toString(),
                        validate: value => value === getValues('newPassword') || t('passwordsDoesNotMatch').toString()
                    })}
                />
                {errors.confirmNewPassword !== undefined && (
                    <FormErrorMessage as='span'>{errors.confirmNewPassword.message}</FormErrorMessage>
                )}
            </FormControl>
            <Button
                colorScheme='blue'
                isDisabled={!isDirty}
                isLoading={isSubmitting}
                leftIcon={<FontAwesomeIcon icon={faCheck} />}
                type='submit'
            >
                {t('validate')}
            </Button>
        </Flex>
    );
};

export default ChangePasswordForm;