// Chakra
import { VStack } from '@chakra-ui/react';

// View models
import { IApplicationUserForUserList } from 'viewmodels/ApplicationUser/IApplicationUserForUserList';

// Components
import UserListItem from './UserListItem';

interface IUserListProps {
    users: IApplicationUserForUserList[];
    editUserHandler: Function;
};

const UserList: React.FC<IUserListProps> = ({
    users,
    editUserHandler
}) => {
    return (
        <VStack
            alignItems='stretch'
            as='ul'
            spacing='1rem'
        >
            {
                users.map((user) => {
                    return (
                        <UserListItem
                            key={user.id}
                            user={user}
                            editUserButtonClickHandler={editUserHandler}
                        />
                    );
                })
            }
        </VStack>
    );
};

export default UserList;