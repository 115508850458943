// Services
import requests from './ApiService';

// Models
import { IActivityRequestForActivityEdition } from 'models/Activity/IActivityRequestForActivityEdition';
import { IActivityResponseForRemainingWorkTime } from 'models/Activity/IActivityResponseForRemainingWorkTime';
import { IActivityResponseForTotalWorkTimePerDay } from 'models/Activity/IActivityResponseForTotalWorkTimePerDay';

// View models
import { IActivityForActivityList } from 'viewmodels/Activity/IActivityForActivityList';
import { IDataForActivities } from 'viewmodels/Activities/IDataForActivities';

const routeName = 'activities';

const ActivityService = {
    fetchMyActivitiesForTotalWorkTimePerDay: (urlParameters: URLSearchParams): Promise<IActivityResponseForTotalWorkTimePerDay[]> =>
        requests.getWithUrlParameters(`${routeName}/totalworktimeperday`, urlParameters),
    fetchDataForActivities: (urlParameters: URLSearchParams): Promise<IDataForActivities> =>
        requests.getWithUrlParameters(`${routeName}/activitiesview`, urlParameters),
    fetchRemainingWorkTime: (urlParameters: URLSearchParams) : Promise<IActivityResponseForRemainingWorkTime> =>
        requests.getWithUrlParameters(`${routeName}/remainingworktime`, urlParameters),
    createMyActivity: (model: IActivityRequestForActivityEdition): Promise<IActivityForActivityList> =>
        requests.post(`${routeName}`, model),
    updateMyActivity: (id: number, model: IActivityRequestForActivityEdition): Promise<IActivityForActivityList> =>
        requests.put(`${routeName}/${id}`, model),
    deleteMyActivity: (id: number): Promise<void> => requests.delete(`${routeName}/${id}`)
};

export default ActivityService;