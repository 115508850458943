// Chakra
import { Text } from '@chakra-ui/react';

interface IEmptyListProps {
    message: string;
};

const EmptyList: React.FC<IEmptyListProps> = ({ message }) => {
    return (
        <Text
            as='span'
            textAlign='center'
        >
            {message}
        </Text>
    );
};

export default EmptyList;