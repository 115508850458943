// Chakra
import {
    Center,
    Spinner
} from '@chakra-ui/react';

const Loader = () => {
    return (
        <Center>
            <Spinner />
        </Center>
    );
};

export default Loader;