import {
    useEffect,
    useState
} from 'react';

import 'react-datepicker/dist/react-datepicker.css';

// Chakra
import {
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Text,
    Textarea
} from '@chakra-ui/react';

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-light-svg-icons';

// I18next
import { useTranslation } from 'react-i18next';

// Hook form
import {
    Controller,
    useController,
    useForm
} from 'react-hook-form';

// Date picker
import DatePicker from 'react-datepicker';

// Select
import Select from 'react-select';

// Day
import dayjs from 'day';

// Hooks
import useIsComputerScreenSize from 'hooks/useIsComputerScreenSize';

// Utilities
import { anyElement } from 'utilities/arrayUtilities';
import { isNullOrWhiteSpace } from 'utilities/stringUtilities';

// Form values
import { ActivityFormValues } from 'formValues/ActivityFormValues';

// View models
import { IActivityType } from 'viewmodels/ActivityType/IActivityType';
import { ICustomer } from 'viewmodels/Customer/ICustomer';
import { ITaskForActivityEdition } from 'viewmodels/Task/ITaskForActivityEdition';

interface IActivityFormProps {
    formValues?: ActivityFormValues;
    tasks: ITaskForActivityEdition[];
    activityTypes: IActivityType[];
    timeMode?: 'StartAndEnd' | 'Duration';
    disabledDaysOfWeek?: number[];
    isDescriptionRequired: boolean;
    isSubmitting: boolean;
    submitHandler: Function;
};

const ActivityForm: React.FC<IActivityFormProps> = ({
    formValues,
    tasks,
    activityTypes,
    timeMode = 'StartAndEnd',
    disabledDaysOfWeek,
    isDescriptionRequired,
    isSubmitting,
    submitHandler
}) => {
    const { t } = useTranslation();
    const {
        register,
        control,
        watch,
        getValues,
        handleSubmit,
        formState: {
            isDirty,
            errors
        }
    } = useForm<ActivityFormValues>({
        defaultValues: {
            date: formValues?.date ?? new Date(),
            startTime: formValues?.startTime ?? '',
            endTime: formValues?.endTime ?? '',
            duration: formValues?.duration ?? '',
            description: formValues?.description ?? '',
            taskId: formValues?.taskId ?? '',
            activityTypeId: formValues?.activityTypeId ?? ''
        }
    });
    const taskId = watch('taskId');
    const [customerDetails, setCustomerDetails] = useState<ICustomer | null>(null);
    const isComputerScreenSize = useIsComputerScreenSize();
    const taskOptions = tasks.map((task) => {
        return {
            value: task.id.toString(),
            label: task.name
        };
    });
    const activityTypeOptions = activityTypes.map((activityType) => {
        return {
            value: activityType.id.toString(),
            label: activityType.name
        };
    });

    useEffect(() => {
        if (!isNullOrWhiteSpace(taskId)) {
            setCustomerDetails(getCustomerDetailsFromSelectedTask(Number(taskId)));
        } else {
            setCustomerDetails(null);
        }
    }, [taskId]);

    const getTaskDefaultValue = () => {
        if (formValues === undefined) {
            return null;
        }
        return taskOptions.find(x => x.value === formValues.taskId) ?? null;
    };

    const getActivityTypeDefaultValue = () => {
        if (formValues === undefined) {
            return null;
        }
        return activityTypeOptions.find(x => x.value === formValues.activityTypeId) ?? null;
    };

    const isDatePickerDayEnabled = (date: Date) => {
        if (disabledDaysOfWeek === undefined || !anyElement(disabledDaysOfWeek)) {
            return true;
        }
        if (disabledDaysOfWeek.includes(date.getDay())) {
            return false;
        }
        return true;
    };

    const getCustomerDetailsFromSelectedTask = (taskId: number) => {
        const task = tasks.find(x => x.id === taskId);
        if (task === undefined) return null;
        return task.customer;
    };

    const handleSubmitForm = (model: ActivityFormValues) => {
        if (!isDirty || isSubmitting) return;
        submitHandler(model);
    };

    return (
        <Flex
            as='form'
            direction='column'
            onSubmit={handleSubmit(handleSubmitForm)}
        >
            <FormControl
                id='taskId'
                isInvalid={errors.taskId !== undefined}
                isRequired
                mb='1rem'
            >
                <FormLabel>{t('task')}</FormLabel>
                <SearchableSelect
                    name='taskId'
                    control={control}
                    options={taskOptions}
                    defaultValue={getTaskDefaultValue()}
                    placeholder={t('defaultTaskSelectOption')}
                    isRequired={t('taskRequired').toString()}
                />
                {errors.taskId !== undefined && (
                    <FormErrorMessage as='span'>{errors.taskId.message}</FormErrorMessage>
                )}
            </FormControl>
            {customerDetails !== null && (
                <Flex
                    direction='column'
                    mb='1rem'
                >
                    <Text
                        as='span'
                        fontWeight='700'
                    >
                        {t('customer')}
                    </Text>
                    <Text as='span'>{customerDetails.name}</Text>
                </Flex>
            )}
            <FormControl
                id='activityTypeId'
                isInvalid={errors.activityTypeId !== undefined}
                isRequired
                mb='1rem'
            >
                <FormLabel>{t('activityType')}</FormLabel>
                <SearchableSelect
                    name='activityTypeId'
                    control={control}
                    options={activityTypeOptions}
                    defaultValue={getActivityTypeDefaultValue()}
                    placeholder={t('defaultActivityTypeSelectOption')}
                    isRequired={t('activityTypeRequired').toString()}
                />
                {errors.activityTypeId !== undefined && (
                    <FormErrorMessage as='span'>{errors.activityTypeId.message}</FormErrorMessage>
                )}
            </FormControl>
            <FormControl
                id='date'
                isInvalid={errors.date !== undefined}
                isRequired
                mb='1rem'
            >
                <FormLabel>{t('date')}</FormLabel>
                <Controller
                    name='date'
                    control={control}
                    render={({
                        field: {
                            value,
                            onChange
                        }
                    }) => (
                        <DatePicker
                            dateFormat='dd/MM/yyyy'
                            filterDate={(date) => isDatePickerDayEnabled(date)}
                            withPortal={!isComputerScreenSize}
                            selected={value}
                            onChange={onChange}
                        />
                    )}
                />
                {errors.date !== undefined && (
                    <FormErrorMessage as='span'>{errors.date.message}</FormErrorMessage>
                )}
            </FormControl>
            {
                timeMode !== 'StartAndEnd' ?
                (
                    <FormControl
                        id='duration'
                        isInvalid={errors.duration !== undefined}
                        isRequired
                        mb='1rem'
                    >
                        <FormLabel>{t('duration')}</FormLabel>
                        <Input
                            type='time'
                            variant='filled'
                            {...register('duration', {
                                required: t('durationRequired').toString(),
                                pattern: {
                                    value: /^([0-1][0-9]|[2][0-3]):([0-5][0-9])$/,
                                    message: t('invalidTimeFormat')
                                },
                                validate: value => {
                                    if (Number(value.substr(0, 2)) === 0 && Number(value.substr(3, 2)) === 0) {
                                        return t('invalidDuration').toString();
                                    }
                                    return true;
                                }
                            })}
                        />
                        {errors.duration !== undefined && (
                            <FormErrorMessage as='span'>{errors.duration.message}</FormErrorMessage>
                        )}
                    </FormControl>
                ) :
                (
                    <Flex
                        direction={{
                            base: 'column',
                            lg: 'row'
                        }}
                        mb='1rem'
                    >
                        <FormControl
                            id='startTime'
                            isInvalid={errors.startTime !== undefined}
                            isRequired
                            mb={{
                                base: '1rem',
                                lg: '0'
                            }}
                            mr={{
                                base: 0,
                                lg: '1rem'
                            }}
                        >
                            <FormLabel>{t('startTime')}</FormLabel>
                            <Input
                                type='time'
                                variant='filled'
                                {...register('startTime', {
                                    required: t('startTimeRequired').toString(),
                                    pattern: {
                                        value: /^([0-1][0-9]|[2][0-3]):([0-5][0-9])$/,
                                        message: t('invalidTimeFormat')
                                    },
                                    validate: value => {
                                        if (Number(value.substr(0, 2)) === 0 && Number(value.substr(3, 2)) === 0) {
                                            return t('invalidTime').toString();
                                        }
                                        return true;
                                    }
                                })}
                            />
                            {errors.startTime !== undefined && (
                                <FormErrorMessage as='span'>{errors.startTime.message}</FormErrorMessage>
                            )}
                        </FormControl>
                        <FormControl
                            id='endTime'
                            isInvalid={errors.endTime !== undefined}
                            isRequired
                        >
                            <FormLabel>{t('endTime')}</FormLabel>
                            <Input
                                type='time'
                                variant='filled'
                                {...register('endTime', {
                                    required: t('endTimeRequired').toString(),
                                    pattern: {
                                        value: /^([0-1][0-9]|[2][0-3]):([0-5][0-9])$/,
                                        message: t('invalidTimeFormat')
                                    },
                                    validate: value => {
                                        if (Number(value.substr(0, 2)) === 0 && Number(value.substr(3, 2)) === 0) {
                                            return t('invalidTime').toString();
                                        }
                                        const difference = dayjs(
                                            dayjs.duration({
                                                hours: Number(value.substr(0, 2)),
                                                minutes: Number(value.substr(3, 2))
                                            }).asMilliseconds()
                                        ).diff(
                                            dayjs.duration({
                                                hours: Number(getValues('startTime').substr(0, 2)),
                                                minutes: Number(getValues('startTime').substr(3, 2))
                                            }).asMilliseconds()
                                        );
                                        if (difference <= 0) {
                                            return t('endTimeMustBeLaterThanTheStartTime').toString();
                                        }
                                        return true;
                                    }
                                })}
                            />
                            {errors.endTime !== undefined && (
                                <FormErrorMessage as='span'>{errors.endTime.message}</FormErrorMessage>
                            )}
                        </FormControl>
                    </Flex>
                )
            }
            <FormControl
                id='description'
                isInvalid={errors.description !== undefined}
                isRequired={isDescriptionRequired}
                mb='1rem'
            >
                <FormLabel>{t('description')}</FormLabel>
                <Textarea
                    variant='filled'
                    {...register('description', {
                        maxLength: { value: 500, message: t('descriptionMaximumLength', { length: 500 }).toString() },
                        validate: value => {
                            if (isDescriptionRequired && isNullOrWhiteSpace(value)) {
                                return t('descriptionRequired').toString();
                            }
                            return true;
                        }
                    })}
                />
                {errors.description !== undefined && (
                    <FormErrorMessage as='span'>{errors.description.message}</FormErrorMessage>
                )}
            </FormControl>
            <Button
                colorScheme='blue'
                isDisabled={!isDirty}
                isLoading={isSubmitting}
                leftIcon={<FontAwesomeIcon icon={faCheck} />}
                type='submit'
            >
                {t('validate')}
            </Button>
        </Flex>
    );
};

interface ISearchableSelectProps {
    name: string;
    control: any;
    options: {
        value: string,
        label: string
    }[];
    defaultValue?: { value: string, label: string } | null;
    placeholder: string;
    isRequired?: boolean | string;
};

const SearchableSelect: React.FC<ISearchableSelectProps> = ({
    name,
    control,
    options,
    defaultValue,
    placeholder,
    isRequired
}) => {
    const { t } = useTranslation();
    const {
        field: { ref, onChange }
    } = useController({
        name,
        control,
        defaultValue: defaultValue,
        rules: {
            required: isRequired
        }
    });

    return (
        <Select
            defaultValue={defaultValue}
            noOptionsMessage={() => t('noResult')}
            options={options}
            placeholder={placeholder}
            ref={ref}
            onChange={(e) => onChange(e?.value ?? '')}
        />
    );
  }

export default ActivityForm;